












import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import ReportDataTableComponent from '../components/ReportDataTableComponent.vue';
import * as _ from 'lodash';
import ReportHelper from './ReportHelper';
import { ColumnStyleItem, ReportDataTable, ReportDataTableRow, TotalsAggregate } from '@/store/models';
import { EmptyReportDataTable } from '@/store/models-empty';

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class IndigenousProcurementProjectComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public reportHelper: any;
  @Prop() public projectId: any;
  @Prop() public payeeCommunityReportDataTables: any;
  @Prop() public reportMode: any;

  private isLoaded: boolean = false;
  private projectSummaryReportDataTable: ReportDataTable|null = null;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    const totalsAggregate = this.aggregate as TotalsAggregate;
    this.projectSummaryReportDataTable = _.cloneDeep(EmptyReportDataTable);

    this.projectSummaryReportDataTable.headers = (this.reportHelper as ReportHelper).indigneousProcurementSummaryProjectHeaders;
    this.projectSummaryReportDataTable.hideHeaders = true;

    const totalDisColumnStyle: ColumnStyleItem[] = [ { index: 1, fontSize: '16px' }, { index: 2, fontSize: '16px' } ];

    // Summary data table.
    this.projectSummaryReportDataTable.rows.push({ data: ['', 'Total Direct Indigenous Spend', totalsAggregate.indigenous.directIndigenousSpend.valueFormatted], columnStyle: totalDisColumnStyle } as ReportDataTableRow);
    this.projectSummaryReportDataTable.rows.push({  data: ['', 'Total Indigenous Community Owned Business Spend', totalsAggregate.indigenous.communityOwnedSpend.valueFormatted] } as ReportDataTableRow);
    this.projectSummaryReportDataTable.rows.push({  data: ['', 'Total Relationship Spend', totalsAggregate.indigenous.relationshipSpend.valueFormatted] } as ReportDataTableRow);
    this.projectSummaryReportDataTable.rows.push({  data: ['', 'Total Certified Indigenous Owned Business Spend', totalsAggregate.indigenous.certifiedBusinessSpend.valueFormatted] } as ReportDataTableRow);
    this.projectSummaryReportDataTable.rows.push({  data: ['', 'Total Self Declared Indigenous Owned Business Spend', totalsAggregate.indigenous.selfDeclaredBusinessSpend.valueFormatted] } as ReportDataTableRow);
    this.projectSummaryReportDataTable.pageBreakAfter = true;
    this.projectSummaryReportDataTable.totalRow = null;

    this.isLoaded = true;
  }
}
