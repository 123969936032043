
















import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import TargetComponent from "../components/TargetComponent.vue";
import { TotalsAggregate, CategoryAggregateTotalsValue, ReportDataTable, TotalsAggregateValue, TargetDataTableItem, ResourceSearchResult, Expense, ExpenseAggregate } from "../store/models";
import { formatTotalForDisplay } from "../lib/aggregate";
import { EmptyReportDataTable, EmptyReportDataTableRow, EmptyTargetDataTableItem } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper from "./ReportHelper";
import { formatCurrencyToString } from "@/lib/shared";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class SocialProcurementReportComponent extends BaseComponent {
  @Prop() public expenseData!: ExpenseAggregate[];
  @Prop() public overheadExpenseData!: ExpenseAggregate[];
  @Prop() public projectRevenueExpenseData!: { [key: string]: ExpenseAggregate[] };

  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private projectsTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private overheadsTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private contributionsTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshProjectsTable();
    this.refreshOverheadsTable();
    this.refreshContributionsTable();
    this.isLoaded = true;
  }

  private refreshProjectsTable() {
    this.projectsTable.title = "Projects";
    this.projectsTable.className = "margin-bottom";
    this.projectsTable.headers = this.reportHelper.locationProjectsExpenseHeaders;
    this.projectsTable.parentsBold = false;
    this.projectsTable.totalRow = null;

    let revenue = 0;
    let expenses = 0;
    let social = 0;
    let indigenous = 0;
    let diverseValue = 0;
    let value = 0;

    for (const expenseAgg of this.expenseData) {
      revenue += this.projectRevenueExpenseData[expenseAgg.identifier]
        ? this.projectRevenueExpenseData[expenseAgg.identifier][0]
          ? this.projectRevenueExpenseData[expenseAgg.identifier][0].payload.expenses.expenseTotals.totalSpend
          : 0
        : 0;
      expenses += expenseAgg.payload.expenses.expenseTotals.totalSpend;
      social += this.getSocialProcurement(expenseAgg);
      indigenous += this.getTNBTotal(expenseAgg);
      diverseValue += expenseAgg.payload.expenses.employment.totalDiverseEmploymentValue;
      value += expenseAgg.payload.expenses.employment.totalEmploymentValue;
    }

    let employment = (diverseValue / value) * 100;
    if (isNaN(employment)) {
      employment = 0;
    }

    this.projectsTable.rows.push({
      data: [
        "",
        formatCurrencyToString(revenue.toFixed(2), false),
        formatCurrencyToString(expenses.toFixed(2), false),
        formatCurrencyToString(social.toFixed(2), false),
        formatCurrencyToString(indigenous.toFixed(2), false),
        employment.toFixed(2) + "%",
      ],
      children: [],
    });
  }

  private refreshOverheadsTable() {
    this.overheadsTable.title = "Overheads";
    this.overheadsTable.className = "margin-bottom";
    this.overheadsTable.headers = this.reportHelper.locationSummaryOverheadsHeaders;
    this.overheadsTable.parentsBold = false;
    this.overheadsTable.totalRow = null;

    let expenses = 0;
    let social = 0;
    let indigenous = 0;
    let diverseValue = 0;
    let value = 0;

    for (const overhead of this.overheadExpenseData) {
      expenses += overhead.payload.expenses.expenseTotals.totalSpend;
      social += this.getSocialProcurement(overhead);
      indigenous += this.getTNBTotal(overhead);
      diverseValue += overhead.payload.expenses.employment.totalDiverseEmploymentValue;
      value += overhead.payload.expenses.employment.totalEmploymentValue;
    }

    let employment = (diverseValue / value) * 100;
    if (isNaN(employment)) {
      employment = 0;
    }

    this.overheadsTable.rows.push({
      data: ["", formatCurrencyToString(expenses.toFixed(2), false), formatCurrencyToString(social.toFixed(2), false), formatCurrencyToString(indigenous.toFixed(2), false), employment.toFixed(2) + "%"],
      children: [],
    });
  }

  private refreshContributionsTable() {
    this.contributionsTable.title = "Contributions";
    this.contributionsTable.className = "margin-bottom";
    this.contributionsTable.headers = this.reportHelper.locationContributionExpenseHeaders;
    this.contributionsTable.parentsBold = false;
    this.contributionsTable.totalRow = null;

    let project = 0;
    let overhead = 0;
    let total = 0;

    for (const expenseAgg of this.expenseData) {
      project += expenseAgg.payload.expenses.expenseTotals.totalProjectContributions;
      overhead += expenseAgg.payload.expenses.expenseTotals.totalContributions - expenseAgg.payload.expenses.expenseTotals.totalProjectContributions;
      total += expenseAgg.payload.expenses.expenseTotals.totalContributions;
    }

    this.contributionsTable.rows.push({
      data: ["", formatCurrencyToString(project.toFixed(2), false), formatCurrencyToString(overhead.toFixed(2), false), formatCurrencyToString(total.toFixed(2), false)],
      children: [],
    });
  }

  private getSocialProcurement(expenseAgg: ExpenseAggregate): number {
    return expenseAgg
      ? expenseAgg.payload.expenses.socialProcurement.totalIndigenousSpend +
          expenseAgg.payload.expenses.socialProcurement.totalDiverseSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalLocalSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalSmeSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalSociallyFocusedSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalThirdPartyCertifiedSpend
      : 0;
  }

  private getTNBTotal(expenseAgg: ExpenseAggregate): number {
    return expenseAgg
      ? expenseAgg.payload.expenses.expenseTotals.totalIndigenousCommunityContributions +
          expenseAgg.payload.expenses.expenseTotals.totalIndigenousProjectContributions +
          expenseAgg.payload.expenses.employment.totalIndigenousEmploymentValue
      : 0;
  }
}
