





















import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import * as _ from 'lodash';
import { ReportDataTable, ReportDataTableRow, TotalsAggregate, TotalsAggregateValue } from '@/store/models';
import { convertToTotalsAggregateValue } from '@/lib/aggregate';

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class DiversityBreakdownComponent extends BaseComponent {

  @Prop() public reportDataTables: any;
  @Prop() public aggregate: any;
  @Prop() public reportMode: any;

  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate && this.reportDataTables) {
      // Fill in the percentages in the reportDataTables once we know the totalProjectExpenses from the other aggregate response.
      const totalProjectExpenses: number = (this.aggregate as TotalsAggregate).totalProjectExpenses.value;
      for (const reportDataTable of (this.reportDataTables as ReportDataTable[])) {
        // Overall Total.
        reportDataTable.total = convertToTotalsAggregateValue(reportDataTable.total.value, totalProjectExpenses, true);
        reportDataTable.className = 'margin-bottom';

        // Total Row.
        if (reportDataTable.totalRow) {
          if (reportDataTable.totalRow.data.length > 0) {
            reportDataTable.totalRow.data[0] = convertToTotalsAggregateValue((reportDataTable.totalRow.data[0] as TotalsAggregateValue).value, totalProjectExpenses, true);
            reportDataTable.totalRow.data[1] = convertToTotalsAggregateValue((reportDataTable.totalRow.data[1] as TotalsAggregateValue).value, totalProjectExpenses, true);
            reportDataTable.totalRow.data[2] = convertToTotalsAggregateValue((reportDataTable.totalRow.data[2] as TotalsAggregateValue).value, totalProjectExpenses, true);
            reportDataTable.totalRow.data[3] = convertToTotalsAggregateValue((reportDataTable.totalRow.data[3] as TotalsAggregateValue).value, totalProjectExpenses, true);
          }
        }

        // Main rows.
        for (const row of (reportDataTable.rows as ReportDataTableRow[])) {
          this.convertReportDataTableRow(row, totalProjectExpenses);
          for (const child of (row.children)) {
            this.convertReportDataTableRow(child, totalProjectExpenses);
          }
        }
      }
    }
    this.isLoaded = true;
  }

  private convertReportDataTableRow(row: ReportDataTableRow, percentageTotal: number) {
    if (row.data.length > 0) {
      const goods: TotalsAggregateValue = row.data[1] as TotalsAggregateValue; // Goods
      row.data[1] = convertToTotalsAggregateValue(goods.value, percentageTotal, true);
      const services: TotalsAggregateValue = row.data[2] as TotalsAggregateValue; // Services
      row.data[2] = convertToTotalsAggregateValue(services.value, percentageTotal, true);
      const equipment: TotalsAggregateValue = row.data[3] as TotalsAggregateValue; // Equipment
      row.data[3] = convertToTotalsAggregateValue(equipment.value, percentageTotal, true);
      const overall: TotalsAggregateValue = row.data[4] as TotalsAggregateValue; // Overall Total
      row.data[4] = convertToTotalsAggregateValue(overall.value, percentageTotal, true);
    }
  }
}
