export function getEirColumnMapping(): Record<string, Record<string, string>> {
  return {
    barriers: {
      "no": "No",
      "revious_conviction": "Previous criminal conviction.",
      "substance_use_disorder": "Substance use disorder",
      "domestic_violence": "Been a party to domestic violence.",
      "homelessness": "Experienced homelessness within the last 5 years.",
      "single_parent": "Being a single parent.",
      "social_housing": "Living in Social Housing.",
      "youth_foster_care": "Being a youth aging out of the foster care system.",
      "limited_english": "Limited English proficiency",
      "no_HighSchool": "No High-School diploma",
      "dependent_with_special_needs": "Have dependent with special needs",
      "No_Drivers_License": "No Drivers License due to maintenance enforcement",
      "other": "Other Barrier not Listed Above",
      "no_answer": "I’d prefer not to answer",
    },
    disabilities: {
      "no": "No",
      "physical-disability": "Physical Disability",
      "mental-disability": "Mental Disability",
      "psychiatric-disability": "Psychiatric Disability",
      "intellectual-disability": "Intellectual Disability",
      "sensory-disability": "Sensory Disability",
      "no_answer": "I’d prefer not to answer",
    },
    veteran: {
      "yes": "Yes",
      "no": "No",
      "no_answer": "I'd prefer not to answer",
    },
    refugee: {
      "yes": "Yes",
      "no": "No",
      "no_answer": "I'd prefer not to answer",
    },
    immigrationStatusAuthorized: {
      "yes": "Yes",
      "no": "No",
      "no_answer": "I'd prefer not to answer",
    },
    faithReligion: {
      "atheist": "Atheist",
      "agnostic": "Agnostic",
      "jehova": "Jehovah's Witness",
      "buddhist": "Buddhist",
      "confucian": "Confucian",
      "hindu": "Hindu",
      "jewish": "Jewish",
      "muslim": "Muslim",
      "christian": "Christian (Orthodox / Protestant / Roman Catholic / Non Denominational",
      "simple": "I'm simply a spiritual person",
      "sikh": "Sikh",
      "taoist": "Taoist",
      "no_religion": "No Religion",
      "other": "Other Religion not listed above",
      "no_answer": "I Choose Not to answer",
    },
    heritage: {
      "arab": "Arab",
      "black": "Black",
      "caucasian": "Caucasian (White)",
      "filipino": "Filipino",
      "latin_american": "Latin American",
      "east_asian": "East Asian",
      "south_asian": "South Asian",
      "southeast_asian": "Southeast Asian",
      "west_asian": "West Asian",
      "indigenous": "Indigenous",
      "other": "Other Visible Minority not listed above",
      "no_answer": "I Choose Not to answer",
    },
    sexualOrientation: {
      "Bisexual": "Bisexual",
      "Pansexual": "Pansexual",
      "gayfemale": "Gay Woman / Lesbian",
      "gaymale": "Gay Man",
      "Heterosexual": "Heterosexual",
      "none": "I’d prefer not to answer",
    },
    gender: {
      "female": "Woman",
      "male": "Man",
      "transfemale": "Transgender Woman",
      "transmale": "Transgender Man",
      "twospirited": "Two Spirited",
      "agender": "Agender, Non-binary, Gender Fluid and Other Genders not listed",
      "none": "I’d prefer not to answer",
    },
  };
}
