








































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import * as _ from "lodash";
import { Expense, RefDataValue, ReportDataTable, ReportDataTableRow } from "@/store/models";
import { formatCurrencyToString } from "@/lib/shared";
import { EmptyReportDataTable } from "@/store/models-empty";
import * as datetime from "../lib/datetime";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class UNSDGBreakdownReportComponent extends BaseComponent {
  @Prop() public unsdgs!: RefDataValue[];
  @Prop() public unsdgExpenses!: { [key: string]: Expense[] };
  @Prop() public selectedProject!: string;
  @Prop() public selectedCommunity!: string;
  @Prop() public dateRange: any;
  @Prop() public reportMode: any;
  @Prop() public reportHelper: any;

  private unsdgDataTables: Array<{ unsdg: RefDataValue; table: ReportDataTable; total: string; icon: string }> = [];
  private isLoaded: boolean = false;
  private headers: any[] = [
    { text: "Community / Project", rowLayout: 3, align: "left" },
    { text: "Vendor", rowLayout: 2, align: "left" },
    { text: "Spend ($)", rowLayout: 2, align: "left" },
    { text: "Date", rowLayout: 2, align: "left" },
    { text: "Description", rowLayout: 2, align: "left" },
  ];

  get grandTotal() {
    let sum = 0;
    for (const expenses of Object.values(this.unsdgExpenses)) {
      sum += _.sumBy(expenses, (e) => (e.payment.hasBeneficiary ? e.payment.amount || 0 : 0));
    }
    return formatCurrencyToString(sum.toString());
  }

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.unsdgs && this.unsdgExpenses) {
      for (const unsdg of this.unsdgs) {
        const expenses = this.unsdgExpenses[unsdg.code];
        if (expenses) {
          this.createTableData(expenses, unsdg);
        }
      }
    }
    this.isLoaded = true;
  }

  private createTableData(expenses: Expense[], unsdg: RefDataValue) {
    const reportDataTable = _.cloneDeep(EmptyReportDataTable);
    reportDataTable.pageBreakAfter = true;
    reportDataTable.className = "margin-bottom";
    reportDataTable.headers = this.headers.slice();
    reportDataTable.totalRow = null;

    const total = _.sumBy(expenses, (e) => (e.payment.hasBeneficiary ? e.payment.amount || 0 : 0));

    const communities = _.uniqBy(expenses, (e) => (e.payment.hasBeneficiary ? e.payment.hasBeneficiary.identifier : null)).map((e) => (e.payment.hasBeneficiary ? (e.payment.hasBeneficiary as any) : null));
    for (const community of communities) {
      if (community === null) {
        continue;
      }
      const communityExpenses = expenses.filter((e) => (e.payment.hasBeneficiary ? e.payment.hasBeneficiary.identifier === community.identifier : false));
      const communityName = community.displayName || "";
      const subTotal = _.sumBy(communityExpenses, (e) => e.payment.amount || 0);

      const rows: ReportDataTableRow[] = [];
      for (const expense of communityExpenses) {
        const project = expense.payment.hasProject ? (expense.payment.hasProject as any).displayName : "";
        const vendor = expense.payment.hasPayee ? (expense.payment.hasPayee as any).displayName : "Unknown";
        const amount = expense.payment.amount || 0;
        // const percentage = subTotal > 0 ? (amount / subTotal) * 100 : 0;
        const description = expense.payment.description || "";
        rows.push({
          data: [project, vendor, formatCurrencyToString(amount.toString()), datetime.formatDateForDisplay(expense.payment.effectiveDate || ""), description],
          columnStyle: [{ index: 0, indentations: 1 }],
        } as ReportDataTableRow);
      }

      reportDataTable.rows.push({ data: [communityName, "", formatCurrencyToString(subTotal.toString()), "", ""], children: rows } as ReportDataTableRow);
    }
    if (total > 0) {
      this.unsdgDataTables.push({ unsdg, table: reportDataTable, total: formatCurrencyToString(total.toString()), icon: this.getIcon(unsdg) });
    }
  }

  private getIcon(unsdg: RefDataValue) {
    const unsdgNumber = unsdg.code.split("-")[0].replace("goal", "");
    return `/assets/img/unsdg-icons/SDG_Icons_Inverted_Transparent_WEB-${unsdgNumber}.png`;
  }
}
