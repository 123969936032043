































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';
import { getTargetLabelStyle, getTargetLineStyle, getTargetItemStyle, getTargetLineLeft, getTargetLineColor, getTargetScaleItems, getTargetScaleItemStyle } from '../lib/targets';

@Component({
  components: {},
})
export default class TargetComponent extends Vue {

  @Prop() public targetItem: any;

  private getTargetLabelStyle = getTargetLabelStyle;
  private getTargetLineStyle = getTargetLineStyle;
  private getTargetItemStyle = getTargetItemStyle;
  private getTargetLineLeft = getTargetLineLeft;
  private getTargetLineColor = getTargetLineColor;
  private getTargetScaleItems = getTargetScaleItems;
  private getTargetScaleItemStyle = getTargetScaleItemStyle;

  private mounted() {
    // console.log(JSON.stringify(this.targetItem));
  }
}
