





















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import Highcharts, { chart } from "highcharts";
import SimpleChartComponent from './SimpleChartComponent.vue';
import SpinnerComponent from '../components/SpinnerComponent.vue';
import TotalsItemComponent from '../components/TotalsItemComponent.vue';
import { BulletGraphMeta, StackedBarMeta, TotalsAggregate, Vendor, Project, ProjectMeta, TotalsItem, CategoryAggregateTotalsValue, ImpactTargetsMeta } from '../store/models';
import { EmptyBulletGraphMeta, EmptyVendor, EmptyProject, EmptyTotalsItem } from '../store/models-empty';
import { getVendor, getProject } from '../store/rest.service';
import { getIconUrl } from '../lib/images';
import * as colors from '../lib/colors';
import * as charts from '../lib/charts';
import * as datetime from '../lib/datetime';
import { convertToPercent } from '../lib/currency';
import * as _ from 'lodash';
import loadBullet from "highcharts/modules/bullet";
import AggregateHelper from './AggregateHelper';

loadBullet(Highcharts);

@Component({
  components: {
    SimpleChartComponent,
    SpinnerComponent,
    TotalsItemComponent,
  },
})
export default class SocialProcurementComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public projectId: any;
  @Prop() public hideTable: any;
  @Prop() public hideTargets: any;
  @Prop() public reportMode: any;
  @Prop() public showExtraTotals: any;
  @Prop() public hideDiversityMarker: any;
  @Prop() public theme: any;

  private chartData: any = {};

  private socialTotalsItem: TotalsItem = _.cloneDeep(EmptyTotalsItem);
  private totalsItems: TotalsItem[] = [];
  private extraTotalsItems: TotalsItem[] = [];
  private socialItems: any = [];
  private socialTotals: any = {};
  private socialTarget: number = 0;

  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate) {
      this.getTargets();
      const totalsAggregate = (this.aggregate as TotalsAggregate);

      const socialValue: number = this.formatToNumber(totalsAggregate.socialProcurement.overall.percentage, 2) as number;
      this.socialTotalsItem.description = 'SOCIAL PROCUREMENT';
      this.socialTotalsItem.percentageFormatted = totalsAggregate.socialProcurement.overall.percentageFormatted;
      this.socialTotalsItem.amount = totalsAggregate.socialProcurement.overall.value;
      this.socialTotalsItem.isFullWidth = true;

      if (this.hideTargets) {
        this.socialTotalsItem.isFullWidth = false;
        this.socialTotalsItem.rowLayout = 3;
        this.totalsItems.push(this.socialTotalsItem);
      }

      if (this.showExtraTotals) {

        // DIVERSE EMPLOYMENT
        this.extraTotalsItems.push(
          {
            description: 'DIVERSE EMPLOYMENT',
            percentageFormatted: totalsAggregate.employment.indigenousAndDiverse.hours.percentageFormatted,
            amount: totalsAggregate.employment.indigenousAndDiverse.hours.value,
            percentage: totalsAggregate.employment.indigenousAndDiverse.hours.percentage,
            backgroundColor: colors.colorIndigenousAndDiverseEmployment,
            color: colors.colorWhite,
            cssClass: 'totals-item-small-title',
            isCurrency: false,
            units: 'HOURS',
            children: [],
            isFullWidth: false,
            isFirst: true,
            rowLayout: 6,
          } as TotalsItem);

        // LOCAL PROCUREMENT
        this.extraTotalsItems.push(
          {
            description: 'LOCAL PROCUREMENT',
            percentageFormatted: totalsAggregate.diverse.localSpend.percentageFormatted,
            amount: totalsAggregate.diverse.localSpend.value,
            percentage: totalsAggregate.diverse.localSpend.percentage,
            backgroundColor: colors.colorLocalInsights,
            color: colors.colorWhite,
            cssClass: 'totals-item-small-title',
            isCurrency: true,
            children: [],
            isFullWidth: false,
            isLast: true,
            rowLayout: 6,
          } as TotalsItem);
      }

      // CERTIFIED EQUITY SEEKING BUSINESSES
      const eqbItem: TotalsItem = {
        description: 'CERTIFIED EQUITY SEEKING BUSINESSES',
        percentageFormatted: totalsAggregate.socialProcurement.esb.percentageFormatted,
        amount: totalsAggregate.socialProcurement.esb.value,
        percentage: totalsAggregate.socialProcurement.esb.percentage,
        backgroundColor: colors.colorMediumBlue,
        color: colors.colorWhite,
        cssClass: 'totals-item-small-title',
        isCurrency: true,
        children: [],
        isFullWidth: false,
        hasTarget: this.hideTargets ? this.socialTarget : undefined,
        rowLayout: this.hideTargets ? 9 : 12,
        tooltipText: 'Certified Equity Seeking Businesses category includes the total monetary value spent with vendors recognized as Indigenous, Black, Persons of Colour, LGBTQ2S+, Veteran, Women or Disabled individuals. The ownership has been verified by a third-party certifying body.',
      };
      eqbItem.children.push({
        description: 'CERTIFIED INDIGENOUS BUSINESS',
        percentageFormatted: totalsAggregate.socialProcurement.esbIndigenous.percentageFormatted,
        amount: totalsAggregate.socialProcurement.esbIndigenous.value,
        percentage: totalsAggregate.socialProcurement.esbIndigenous.percentage,
        backgroundColor: colors.colorLightBlue,
        color: colors.colorWhite,
        cssClass: 'totals-item-small-title',
        isCurrency: true,
        children: [],
        isFullWidth: false,
        tooltipText: 'Certified Indigenous Business category includes the total monetary value spent with vendors recognized Indigenous individuals and the ownership has been verified by a third-party certifying body.',
      });
      eqbItem.children.push({
        description: 'CERTIFIED EQUITY SEEKING BUSINESS (LESS INDIGENOUS)',
        percentageFormatted: totalsAggregate.socialProcurement.esbDiverse.percentageFormatted,
        amount: totalsAggregate.socialProcurement.esbDiverse.value,
        percentage: totalsAggregate.socialProcurement.esbDiverse.percentage,
        backgroundColor: colors.colorLightBlue,
        color: colors.colorWhite,
        cssClass: 'totals-item-small-title',
        isCurrency: true,
        children: [],
        isFullWidth: false,
        tooltipText: 'Certified Equity Seeking Business (Less Indigenous) category includes the total monetary value spent with vendors recognized as Black, Persons of Colour, LGBTQ2S+, Veteran, Women or Disabled individuals. The ownership has been verified by a third-party certifying body.',
      });
      this.totalsItems.push(eqbItem);

      // Social Purpose Organization
      this.totalsItems.push(
        {
          description: 'SOCIAL PURPOSE ORGANIZATIONS',
          percentageFormatted: totalsAggregate.socialProcurement.thirdPartyCertifiedSpend.percentageFormatted,
          amount: totalsAggregate.socialProcurement.thirdPartyCertifiedSpend.value,
          percentage: totalsAggregate.socialProcurement.thirdPartyCertifiedSpend.percentage,
          backgroundColor: colors.colorMediumBlue,
          color: colors.colorWhite,
          cssClass: 'totals-item-small-title',
          isCurrency: true,
          children: [],
          isFullWidth: false,
          rowLayout: this.hideTargets ? 3 : 6,
          tooltipText: 'Social Purpose Organizations category includes the total monetary value spent with vendors recognized as embracing a social focus. These businesses are third-party certified and include Certified B-Corps, Certified Social Enterprises, Certified Living Wage Employers.',
        } as TotalsItem);

      // SELF-DECLARED EQUITY SEEKING BUSINESS
      this.totalsItems.push(
        {
          description: 'SELF-DECLARED EQUITY SEEKING BUSINESS',
          percentageFormatted: totalsAggregate.socialProcurement.sociallyFocusedSpend.percentageFormatted,
          amount: totalsAggregate.socialProcurement.sociallyFocusedSpend.value,
          percentage: totalsAggregate.socialProcurement.sociallyFocusedSpend.percentage,
          backgroundColor: colors.colorMediumBlue,
          color: colors.colorWhite,
          cssClass: 'totals-item-small-title',
          isCurrency: true,
          children: [],
          isFullWidth: false,
          rowLayout: this.hideTargets ? 3 : 6,
          tooltipText: 'Self-Declared Equity Seeking Business category includes the total monetary value spent with vendors where the ownership of the business has not been verified by an independent certifier however the business claims equity seeking ownership. This includes Indigenous, Black, Persons of Colour, LGBTQ2S+, Women, Veteran or Disabled Ownership.',
        } as TotalsItem);

      // LOCAL SPEND
      this.totalsItems.push(
        {
          description: 'LOCAL SPEND',
          percentageFormatted: totalsAggregate.socialProcurement.localSpend.percentageFormatted,
          amount: totalsAggregate.socialProcurement.localSpend.value,
          percentage: totalsAggregate.socialProcurement.localSpend.percentage,
          backgroundColor: colors.colorLocal,
          color: colors.colorWhite,
          cssClass: 'totals-item-small-title',
          isCurrency: true,
          children: [],
          isFullWidth: false,
          rowLayout: this.hideTargets ? 3 : 6,
          tooltipText: 'Local Spend category includes the total monetary value spent with vendors recognized as local to a project. These businesses do not also fall into the Equity Seeking / Socially Focused Organizations. As an example – a local certified Black owned business would be captured in the Certified Equity Seeking Business (Less Indigenous) value above and not here. A local non equity seeking business would find its spend value here.',
        } as TotalsItem);

      // SMALL ENTERPRISE SPEND
      this.totalsItems.push(
        {
          description: 'SMALL ENTERPRISE SPEND',
          percentageFormatted: totalsAggregate.socialProcurement.smeSpend.percentageFormatted,
          amount: totalsAggregate.socialProcurement.smeSpend.value,
          percentage: totalsAggregate.socialProcurement.smeSpend.percentage,
          backgroundColor: colors.colorSmallEnterprise,
          color: colors.colorWhite,
          cssClass: 'totals-item-small-title',
          isCurrency: true,
          children: [],
          isFullWidth: false,
          rowLayout: this.hideTargets ? 3 : 6,
          tooltipText: 'Small Enterprise Spend includes the total spend with vendors recognized as Small Enterprises where the same vendors do not already qualify in a super priority position of Local or Equity Seeking.',
        } as TotalsItem);

      if (!this.hideTargets) {
        const chartMeta: BulletGraphMeta = _.cloneDeep(EmptyBulletGraphMeta);
        chartMeta.marginLeft = 10;
        chartMeta.exportingEnabled = false,
        chartMeta.height = 90;
        chartMeta.theme = this.theme;

        const plotBands: any = [{
          from: 0,
          to: 100,
          color: colors.colorWhite,
        }];
        chartMeta.series = [{
          data: [{
            y: socialValue,
            target: this.socialTarget,
            color: colors.colorSocial,
          }],
        }];
        if (socialValue > 0 || this.socialTarget) {
          chartMeta.plotBands = plotBands;
        }
        this.chartData = charts.getBulletGraphFromMeta(chartMeta);
      }

      // Social Procurement data table.
      const expenseCategories = totalsAggregate.socialProcurement.expenseDiversityCategories;
      this.socialItems.push(this.getDataTableItem('Certified Indigenous Business', expenseCategories.esbIndigenous));
      this.socialItems.push(this.getDataTableItem('Certified Equity Seeking Business (Less Indigenous)', expenseCategories.esbDiverse));
      this.socialItems.push(this.getDataTableItem('Social Purpose Organizations', expenseCategories.thirdPartyCertifiedSpend));
      this.socialItems.push(this.getDataTableItem('Self-Declared Equity Seeking Business', expenseCategories.sociallyFocusedSpend));
      this.socialItems.push(this.getDataTableItem('Local Spend', expenseCategories.localSpend));
      this.socialItems.push(this.getDataTableItem('Small Enterprise Spend', expenseCategories.smeSpend));
      this.socialItems.push(this.getDataTableItem('Not Diverse / Unknown', expenseCategories.unknown));
      this.socialTotals = this.getDataTableItem('', expenseCategories.overall);
      this.isLoaded = true;
    }
  }

  private getDataTableItem(type: string, item: CategoryAggregateTotalsValue) {
    return {
      type,
      totalGoods: item.goods.valueFormatted + ' (' + item.goods.percentageFormatted + ')',
      totalServices: item.services.valueFormatted + ' (' + item.services.percentageFormatted + ')',
      totalEquipment: item.equipment.valueFormatted + ' (' + item.equipment.percentageFormatted + ')',
      totalUnknown: item.unknown.valueFormatted + ' (' + item.unknown.percentageFormatted + ')',
      total: item.total.valueFormatted + ' (' + item.total.percentageFormatted + ')',
    };
  }

  private getTargets() {
    this.socialTarget = this.impactTargets ? this.zeroIfUndefined(this.impactTargets.socialProcurement) : 0;
  }
}
