












import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import ReportDataTableComponent from './ReportDataTableComponent.vue';
import * as _ from 'lodash';

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class IndigenousProcurementCommunityComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public reportHelper: any;
  @Prop() public communityReportDataTables: any;
  @Prop() public payeeCommunityReportDataTables: any;
  @Prop() public reportMode: any;

  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    this.isLoaded = true;
  }
}
