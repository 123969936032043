


















































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import TargetComponent from "./TargetComponent.vue";
import { ExpenseAggregate, ShortRecord, Project, EmploymentAggregate, Vendor, DiversityDeclarationsMeta, Expense } from "../store/models";
import { EmptyReportDataTable } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper from "./ReportHelper";
import { formatCurrencyToString } from "@/lib/shared";
import { sumBy } from "lodash";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class PehtaBreakdownReportComponent extends BaseComponent {
  @Prop() public projectDetails!: { [key: string]: Project };
  @Prop() public pehtaData!: {
    vendorContributions: Expense[];
    communityContributions: ExpenseAggregate[];
    expenses: ExpenseAggregate[];
    vendors: Vendor[];
    vendorExpenses: ExpenseAggregate[];
    overall: ExpenseAggregate[];
    employment: EmploymentAggregate[];
    indigenousEmployment: EmploymentAggregate[];
    communityEmployment: EmploymentAggregate[];
  };
  @Prop() public indigenousCommunities!: ShortRecord[];
  @Prop() public secure!: boolean;

  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private procurementTables = {
    vendors: _.cloneDeep(EmptyReportDataTable),
    expenses: _.cloneDeep(EmptyReportDataTable),
    totalSpend: _.cloneDeep(EmptyReportDataTable),
  };

  private employmentTables = {
    byLevel: _.cloneDeep(EmptyReportDataTable),
    byCommunity: _.cloneDeep(EmptyReportDataTable),
    byLevelTotal: _.cloneDeep(EmptyReportDataTable),
    byCommunityTotal: _.cloneDeep(EmptyReportDataTable),
  };

  private benefitsTables = {
    expenses: _.cloneDeep(EmptyReportDataTable),
    communities: _.cloneDeep(EmptyReportDataTable),
    indigenousBenefits: _.cloneDeep(EmptyReportDataTable),
  };
  private summaryTable = _.cloneDeep(EmptyReportDataTable);
  private pehtaTable = _.cloneDeep(EmptyReportDataTable);
  private communities: ShortRecord[] = [];
  private isLoaded: boolean = false;

  get pehtaScore(): string {
    const totalIndigenousWages = this.pehtaData.employment
      .filter((e) => {
        return e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.employment.totalWages)
      .reduce((a, b) => a + b, 0);
    const totalIndigenousBenefits = this.pehtaData.communityContributions
      .filter((e) => {
        return e.identifier && e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.expenses.diverseSpend.totalIndigenousSpend)
      .reduce((a, b) => a + b, 0);

    const totalProcurement = this.pehtaData.overall[0].payload.expenses.expenseTotals.totalSpend;
    let totalWages = 0;
    for (const vendor of this.pehtaData.employment) {
      totalWages += vendor.payload.employment.totalWages;
    }

    // indigenous employment + indigenous benefits / employment + benefits + procurement
    return (((totalIndigenousWages + totalIndigenousBenefits) / (totalProcurement + totalWages + totalIndigenousBenefits)) * 100).toFixed(2);
  }

  get totalProcurement(): number {
    return this.pehtaData.overall[0].payload.expenses.expenseTotals.totalSpend;
  }

  get totalProcurementString(): string {
    return formatCurrencyToString(this.totalProcurement.toFixed(2));
  }

  get totalEmploymentString(): string {
    return formatCurrencyToString(
      this.pehtaData.employment
        .map((e) => e.payload.employment.totalWages)
        .reduce((a, b) => a + b, 0)
        .toFixed(2),
    );
  }

  get totalWagesString(): string {
    let totalWages = 0;
    for (const vendor of this.pehtaData.employment) {
      totalWages += vendor.payload.employment.totalWages;
    }
    return formatCurrencyToString(totalWages.toFixed(2), false);
  }

  get overallAgg(): ExpenseAggregate {
    return this.pehtaData.overall[0];
  }

  protected mounted() {
    this.createProcurementTables();
    this.createEmploymentTables();
    this.createBenefitsTables();
    this.createSummaryTable();
    this.isLoaded = true;
  }

  private recordCommunity(community: ShortRecord) {
    if (!this.communities.find((c) => c.identifier === community.identifier)) {
      this.communities.push(community);
    }
  }

  private createProcurementTables() {
    this.procurementTables.vendors.className = "margin-bottom";
    this.procurementTables.vendors.headers = [
      { text: "Indigenous Business Category", rowLayout: 1, align: "left" },
      { text: "Spend", rowLayout: 1, align: "left" },
      { text: "Percentage", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.vendors.parentsBold = false;
    this.procurementTables.vendors.totalRow = null;

    const categories = {
      totalIndigenousCommunityOwnedSpend: "Indigenous Community Owned Business",
      totalIndigenousRelationshipSpend: "Community Relationship",
      totalIndigenousCertifiedBusinessSpend: "Certified Indigenous Owned Business",
      totalIndigenousSelfDeclaredBusinessSpend: "Self-Declared Indigenous Owned Business",
    };

    const rowData: any[] = [];
    for (const [category, title] of Object.entries(categories)) {
      if (this.overallAgg.payload.expenses.diverseSpend.indigenousDetail[category] > 0) {
        rowData.push([
          title,
          formatCurrencyToString(this.overallAgg.payload.expenses.diverseSpend.indigenousDetail[category].toFixed(2)),
          ((this.overallAgg.payload.expenses.diverseSpend.indigenousDetail[category] / this.totalProcurement) * 100).toFixed(2) + "%",
        ]);
      }
    }

    for (const data of rowData.sort((a, b) => {
      if (a[0] !== b[0]) {
        return a[0] > b[0] ? 1 : -1;
      }
      return a[1] > b[1] ? 1 : -1;
    })) {
      this.procurementTables.vendors.rows.push({
        data,
        children: [],
      });
    }

    this.procurementTables.expenses.className = "margin-bottom";
    this.procurementTables.expenses.headers = [
      { text: "Indigenous Community", rowLayout: 1, align: "left" },
      { text: "Spend", rowLayout: 1, align: "left" },
      { text: "Percentage", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.expenses.parentsBold = false;
    this.procurementTables.expenses.totalRow = null;

    for (const expense of this.pehtaData.expenses.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      if (expense.identifier && expense.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
        const community = { displayName: expense.displayName, identifier: expense.identifier };
        this.recordCommunity(community);
        this.procurementTables.expenses.rows.push({
          data: [
            community.displayName,
            formatCurrencyToString(expense.payload.expenses.expenseTotals.totalSpend.toFixed(2), false),
            ((expense.payload.expenses.expenseTotals.totalSpend / this.totalProcurement) * 100).toFixed(2) + "%",
          ],
          children: [],
        });
      }
    }

    const indigenousProcurement = this.overallAgg.payload.expenses.diverseSpend.totalDirectIndigenousSpend;

    this.procurementTables.totalSpend.className = "margin-bottom";
    this.procurementTables.totalSpend.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.procurementTables.totalSpend.parentsBold = false;
    this.procurementTables.totalSpend.totalRow = null;

    this.procurementTables.totalSpend.rows.push({
      data: ["Total Indigenous Commmunity Procurement", formatCurrencyToString(indigenousProcurement.toFixed(2), false), ((indigenousProcurement / this.totalProcurement) * 100 || 0).toFixed(2) + "%"],
      children: [],
    });
  }

  private createEmploymentTables() {
    this.employmentTables.byLevel.className = "margin-bottom";
    this.employmentTables.byLevel.headers = [
      { text: "Level Title", rowLayout: 1, align: "left" },
      { text: "Total Payroll", rowLayout: 1, align: "left" },
      { text: "Indigneous Community Payroll", rowLayout: 1, align: "left" },
      { text: "% Indigneous Payroll", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.byLevel.parentsBold = false;
    this.employmentTables.byLevel.totalRow = null;

    const totalIndigenousWages = this.pehtaData.indigenousEmployment.map((e) => e.payload.employment.totalWages).reduce((a, b) => a + b, 0);
    const totalWages = this.pehtaData.employment.map((e) => e.payload.employment.totalWages).reduce((a, b) => a + b, 0);

    for (const level of this.pehtaData.employment.sort((a, b) => (a.identifier > b.identifier ? 1 : -1))) {
      const indigneousRecord = this.pehtaData.indigenousEmployment.find((e) => e.identifier === level.identifier);
      const indigenousWages = indigneousRecord ? formatCurrencyToString(indigneousRecord.payload.employment.totalWages.toFixed(2)) : "";
      const indigenousPercentage = indigneousRecord ? ((indigneousRecord.payload.employment.totalWages / totalWages) * 100).toFixed(2) + "%" : "";

      this.employmentTables.byLevel.rows.push({
        data: [level.identifier, formatCurrencyToString(level.payload.employment.totalWages.toFixed(2)), indigenousWages, indigenousPercentage],
        children: [],
      });
    }

    this.employmentTables.byLevelTotal.className = "margin-bottom";
    this.employmentTables.byLevelTotal.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.byLevelTotal.parentsBold = false;
    this.employmentTables.byLevelTotal.totalRow = null;

    this.employmentTables.byLevelTotal.rows.push({
      data: [
        "Total Indigenous Community Employment",
        formatCurrencyToString(totalWages.toFixed(2)),
        formatCurrencyToString(totalIndigenousWages.toFixed(2)),
        ((totalIndigenousWages / totalWages) * 100 || 0).toFixed(2) + "%",
      ],
      children: [],
    });

    this.employmentTables.byCommunity.className = "margin-bottom";
    this.employmentTables.byCommunity.headers = [
      { text: "Community", rowLayout: 1, align: "left" },
      { text: "Headcount", rowLayout: 1, align: "left" },
      { text: "Indigneous Community Payroll", rowLayout: 1, align: "left" },
      { text: "% Indigneous Payroll", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.byCommunity.parentsBold = false;
    this.employmentTables.byCommunity.totalRow = null;

    const totalHeadcount = this.pehtaData.communityEmployment
      .filter((e) => e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999")
      .map((e) => e.payload.employment.headcount)
      .reduce((a, b) => a + b, 0);
    for (const community of this.pehtaData.communityEmployment.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      if (community.identifier && community.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
        this.recordCommunity(community);
        this.employmentTables.byCommunity.rows.push({
          data: [
            community.displayName,
            community.payload.employment.headcount.toFixed(0),
            formatCurrencyToString(community.payload.employment.totalWages.toFixed(2)),
            ((community.payload.employment.totalWages / totalWages) * 100 || 0).toFixed(2) + "%",
          ],
          children: [],
        });
      }
    }

    this.employmentTables.byCommunityTotal.className = "margin-bottom";
    this.employmentTables.byCommunityTotal.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.employmentTables.byCommunityTotal.parentsBold = false;
    this.employmentTables.byCommunityTotal.totalRow = null;

    this.employmentTables.byCommunityTotal.rows.push({
      data: ["Total Indigenous Community Employment", totalHeadcount.toFixed(0), formatCurrencyToString(totalIndigenousWages.toFixed(2)), ((totalIndigenousWages / totalWages) * 100 || 0).toFixed(2) + "%"],
      children: [],
    });
  }

  private createBenefitsTables() {
    this.benefitsTables.communities.className = "margin-bottom";
    this.benefitsTables.communities.headers = [
      { text: "Community", rowLayout: 1, align: "left" },
      { text: "Payee", rowLayout: 1, align: "left" },
      { text: "Type", rowLayout: 1, align: "left" },
      { text: "Amount", rowLayout: 1, align: "left" },
      { text: "Comments", rowLayout: 1, align: "left" },
    ] as any[];
    this.benefitsTables.communities.parentsBold = false;
    this.benefitsTables.communities.totalRow = null;

    const rowData: any[] = [];
    for (const expense of this.pehtaData.vendorContributions) {
      if (expense.payment.hasBeneficiary) {
        const community = expense.payment.hasBeneficiary.displayName;
        const vendorSpend = expense.payment.amount as number;
        const vendor = expense.payment.hasPayee ? (expense.payment.hasPayee as any).identifier : "";
        const vendorDetails = this.pehtaData.vendors.find((v) => (v.common ? v.common.identifier : undefined) === vendor);

        rowData.push([
          community,
          vendorDetails ? (vendorDetails.common ? vendorDetails.common.displayName : "") : "",
          vendorDetails ? this.getSupplyChainDiversityDescription(vendorDetails.diversityDeclarations) : "",
          formatCurrencyToString(vendorSpend.toFixed(2), false),
          expense.payment.description,
        ]);
      }
    }

    for (const data of rowData.sort((a, b) => {
      if (a[0] !== b[0]) {
        return a[0] > b[0] ? 1 : -1;
      }
      return a[1] > b[1] ? 1 : -1;
    })) {
      this.benefitsTables.communities.rows.push({
        data,
        children: [],
      });
    }

    this.benefitsTables.expenses.className = "margin-bottom";
    this.benefitsTables.expenses.headers = [
      { text: "Community Summary", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "Amount", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.benefitsTables.expenses.parentsBold = false;
    this.benefitsTables.expenses.totalRow = null;

    for (const contribution of this.pehtaData.communityContributions.sort((a, b) => (a.displayName > b.displayName ? 1 : -1))) {
      if (contribution.identifier && contribution.identifier !== "urn:nisto-link:id:non-indigenous:no-999999") {
        const community = { displayName: contribution.displayName, identifier: contribution.identifier };
        this.recordCommunity(community);
        this.benefitsTables.expenses.rows.push({
          data: [community.displayName, "", "", formatCurrencyToString(contribution.payload.expenses.diverseSpend.totalIndigenousSpend.toFixed(2), false), ""],
          children: [],
        });
      }
    }

    this.benefitsTables.indigenousBenefits.className = "margin-bottom";
    this.benefitsTables.indigenousBenefits.headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    this.benefitsTables.indigenousBenefits.parentsBold = false;
    this.benefitsTables.indigenousBenefits.totalRow = null;

    const benefitTotal = this.pehtaData.communityContributions
      .filter((e) => {
        return e.identifier && e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.expenses.diverseSpend.totalIndigenousSpend)
      .reduce((a, b) => a + b, 0);
    this.benefitsTables.indigenousBenefits.rows.push({
      data: ["Total Indigenous Community Benefit", "", "", formatCurrencyToString(benefitTotal.toFixed(2), false), ""],
      children: [],
    });
  }

  private createSummaryTable() {
    this.pehtaTable.className = "margin-bottom";
    this.pehtaTable.headers = [
      { text: "Total Revenue", rowLayout: 1, align: "left" },
      { text: "Total Indigenous Benefits", rowLayout: 1, align: "left" },
      { text: "Pehta Score", rowLayout: 1, align: "left" },
    ] as any[];
    // if (this.secure) {
    //   this.pehtaTable.headers.pop();
    // }
    this.pehtaTable.parentsBold = false;
    this.pehtaTable.totalRow = null;

    const revenue = this.pehtaData.overall[0].payload.expenses.expenseTotals.totalSpend;
    const benefitTotal = this.pehtaData.communityContributions
      .filter((e) => {
        return e.identifier && e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.expenses.diverseSpend.totalIndigenousSpend)
      .reduce((a, b) => a + b, 0);

    this.pehtaTable.rows.push({
      data: [formatCurrencyToString(revenue.toFixed(2)), formatCurrencyToString(benefitTotal.toFixed(2)), this.pehtaScore + "%"],
      children: [],
    });

    // if (this.secure) {
    //   this.summaryTable.totalRow = {
    //     data: [formatCurrencyToString(totals.procurement.toFixed(2)), formatCurrencyToString(totals.employment.toFixed(2))],
    //     children: [],
    //   };
    // } else {
    //   this.summaryTable.totalRow = {
    //     data: [formatCurrencyToString(totals.procurement.toFixed(2)), formatCurrencyToString(totals.employment.toFixed(2)), formatCurrencyToString(totals.contributions.toFixed(2))],
    //     children: [],
    //   };
    // }

    this.summaryTable.className = "margin-bottom";
    this.summaryTable.headers = [
      { text: "Community", rowLayout: 1, align: "left" },
      { text: "Procurement", rowLayout: 1, align: "left" },
      { text: "Employment", rowLayout: 1, align: "left" },
      { text: "Benefits", rowLayout: 1, align: "left" },
    ] as any[];
    this.summaryTable.parentsBold = false;
    this.summaryTable.totalRow = null;

    const totals = {
      procurement: 0,
      employment: 0,
      contributions: 0,
    };
    for (const community of this.communities.sort((a, b) => ((a.displayName as string) > (b.displayName as string) ? 1 : -1))) {
      const procurement = this.pehtaData.expenses.find((expense) => expense.identifier === community.identifier);
      const employment = this.pehtaData.communityEmployment.find((e) => e.identifier === community.identifier);
      const contributions = this.pehtaData.communityContributions.find((contribution) => contribution.identifier === community.identifier);

      totals.procurement += procurement ? procurement.payload.expenses.expenseTotals.totalSpend : 0;
      totals.employment += employment ? employment.payload.employment.totalWages : 0;
      totals.contributions += contributions ? contributions.payload.expenses.diverseSpend.totalIndigenousSpend : 0;

      this.summaryTable.rows.push({
        data: [
          community.displayName,
          procurement ? formatCurrencyToString(procurement.payload.expenses.expenseTotals.totalSpend.toFixed(2), false) : "",
          employment ? formatCurrencyToString(employment.payload.employment.totalWages.toFixed(2), false) : "",
          contributions ? formatCurrencyToString(contributions.payload.expenses.diverseSpend.totalIndigenousSpend.toFixed(2), false) : "",
        ],
        children: [],
      });
    }

    this.summaryTable.totalRow = {
      data: [formatCurrencyToString(totals.procurement.toFixed(2)), formatCurrencyToString(totals.employment.toFixed(2)), formatCurrencyToString(totals.contributions.toFixed(2))],
      children: [],
    };
  }

  private getSupplyChainDiversityDescription(declarations: DiversityDeclarationsMeta): string {
    const indigenousImpact = declarations.indigenousImpact ? declarations.indigenousImpact.values : [];
    const impactESG = declarations.impactESG ? declarations.impactESG.values : [];
    const socialFocusedOrgs = declarations.socialFocusedOrgs ? declarations.socialFocusedOrgs.values : [];

    if (indigenousImpact.includes("indigenousOwned")) {
      return "Indigenous Community Owned Business";
    }
    if (impactESG.includes("indigenousOwned")) {
      return "Certified Indigenous Owned Business";
    }
    if (socialFocusedOrgs.includes("indigenousFocused")) {
      return "Self-Declared Indigenous Owned Business";
    }
    return "Community Relationship";
  }
}
