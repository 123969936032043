


































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import TargetComponent from "./TargetComponent.vue";
import { Aggregate, EmploymentAggregate, ExpenseAggregate, Project, ShortRecord, TotalsMeta, Vendor } from "../store/models";
import * as _ from "lodash";
import ReportHelper from "./ReportHelper";
import { formatCurrencyToString } from "@/lib/shared";
import * as datetime from "../lib/datetime";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class PehtaSummaryReportComponent extends BaseComponent {
  @Prop() public projectDetails!: { [key: string]: Project };
  @Prop() public pehtaData!: {
    vendorContributions: ExpenseAggregate[];
    communityContributions: ExpenseAggregate[];
    employment: EmploymentAggregate[];
    expenses: ExpenseAggregate[];
    vendors: Vendor[];
    vendorExpenses: ExpenseAggregate[];
    overall: ExpenseAggregate[];
  };
  @Prop() public organizationName: any;
  @Prop() public dateRange: any;

  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private isLoaded: boolean = false;

  protected mounted() {
    this.isLoaded = true;
  }

  get grossValue(): string {
    // const totals = (this.pehtaData.projectAgggregate.totals as TotalsMeta).revenue;
    // const spend = (totals ? totals.revenueTotals.totalRevenue : 0).toFixed(2);
    const spend = this.pehtaData.overall[0].payload.expenses.expenseTotals.totalSpend.toFixed(2);
    return formatCurrencyToString(spend, false);
  }

  get pehtaScore(): string {
    const totalIndigenousWages = this.pehtaData.employment
      .filter((e) => {
        return e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.employment.totalWages)
      .reduce((a, b) => a + b, 0);
    const totalIndigenousBenefits = this.pehtaData.communityContributions
      .filter((e) => {
        return e.identifier && e.identifier !== "urn:nisto-link:id:non-indigenous:no-999999";
      })
      .map((e) => e.payload.expenses.diverseSpend.totalIndigenousSpend)
      .reduce((a, b) => a + b, 0);

    const totalProcurement = this.pehtaData.overall[0].payload.expenses.expenseTotals.totalSpend;
    // const totalBenefits = this.pehtaData.communityContributions.map((e) => e.payload.expenses.diverseSpend.totalIndigenousSpend).reduce((a, b) => a + b, 0);
    let totalWages = 0;
    for (const vendor of this.pehtaData.employment) {
      totalWages += vendor.payload.employment.totalWages;
    }

    // indigenous employment + indigenous benefits / employment + benefits + procurement
    return (((totalIndigenousWages + totalIndigenousBenefits) / (totalProcurement + totalWages + totalIndigenousBenefits)) * 100).toFixed(2);
  }

  get today(): string {
    return datetime.formatDateForDisplay(new Date().toDateString());
  }
}
