















import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import TargetsTableComponent from "./TargetsTableComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import DiversityBreakdownComponent from "./DiversityBreakdownComponent.vue";
import CompactEIRVisualComponent from "./CompactEIRVisualComponent.vue";
import { ReportDataTable, EIRCrossAnalysis, ColumnStyleItem, EIRData } from "../store/models";
import { EmptyReportDataTable, EmptyReportDataTableRow } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper, { ReportType } from "./ReportHelper";
import { sum } from "lodash";
import { getEirColumnMapping } from "../lib/eir-column-mapping";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    DiversityBreakdownComponent,
    TargetsTableComponent,
    CompactEIRVisualComponent,
  },
})
export default class EIRDistributionComponent extends BaseComponent {
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;
  @Prop() public enabledFeatures: any;
  @Prop() public headlineText: any;
  @Prop() public levelData: any;
  @Prop() public locationData: any;
  @Prop() public hourlyOccupationData: any;
  @Prop() public totalData: any;
  @Prop() public headcountData: any;
  @Prop({ default: "" }) public question!: string;
  @Prop({ default: 1 }) public levelCutoff!: number;
  @Prop({ default: 1 }) public locationCutoff!: number;
  @Prop({ default: 1 }) public occupationCutoff!: number;

  private distributionByLevelTables: ReportDataTable[] = [];
  private isLoaded: boolean = false;
  private chunkSize = 5;

  protected mounted() {
    this.refreshDistributionByLevelTable();
    this.isLoaded = true;
  }

  private refreshDistributionByLevelTable() {
    if (this.levelData) {
      const levelData = this.levelData as EIRData;
      const headerData = this.getHeaders(levelData);

      for (let i = 0; i < headerData.headers.length; i += this.chunkSize) {
        const headers = headerData.headers.slice(i, i + this.chunkSize);
        const headerValues = headerData.headerValues.slice(i, i + this.chunkSize);
        const table = _.cloneDeep(EmptyReportDataTable);

        table.parentsBold = false;
        table.title = "By Level";
        table.className = "margin-bottom";
        table.totalRow = null;

        const tableHeaders: any[] = [{ text: "", rowLayout: 1, align: "left" }].concat(headers);
        table.headers = tableHeaders;

        for (const row of levelData.totals.cross_analysis) {
          const denominator = new Set(row.columns.flatMap((col) => col.codes)).size;
          const rowData = new Array(headerValues.length).fill("-");

          for (const col of row.columns) {
            const columnValue = col.column_value ? col.column_value : "Not Specified";
            const columnName = this.toTitleCase(columnValue, levelData.reportMeta.column);
            const colIndex = headerValues.indexOf(columnName);

            if (colIndex > -1 && denominator >= this.levelCutoff) {
              const value = (col.codes.length / denominator) * 100;
              rowData[colIndex] = `${value.toFixed(1)}%`;
            }
          }
          table.rows.push({
            data: [row._id.row ? row._id.row : "Not Specified"].concat(rowData),
            children: [],
          });
        }
        table.rows.sort((a, b) => a.data[0].localeCompare(b.data[0]));
        this.distributionByLevelTables.push(table);
      }
    }
  }

  private getHeaders(data: EIRData): { headers: any; headerValues: string[] } {
    const headers: any[] = [];
    const headerValues: string[] = [];

    for (const row of data.totals.cross_analysis) {
      for (const col of row.columns) {
        const columnValue = col.column_value ? col.column_value : "Not Specified";
        const columnName = this.toTitleCase(columnValue, data.reportMeta.column);

        if (!headerValues.includes(columnName)) {
          headerValues.push(columnName);
          headers.push({
            text: columnName,
            rowLayout: 1,
            align: "left",
          });
        }
      }
    }

    headers.sort((a, b) => this.sortFunction(a.text, b.text));
    headerValues.sort((a, b) => this.sortFunction(a, b));

    return { headers, headerValues };
  }

  private toTitleCase(str: string, column: string): string {
    const mapping = getEirColumnMapping();
    const columnMapping = column.split(".").pop();

    if (columnMapping && mapping[columnMapping] && mapping[columnMapping][str]) {
      return mapping[columnMapping][str];
    }

    switch (str) {
      case "m\u00e9tis":
        return "M\u00e9tis";
      case "bachelor's degree":
      case "bachelor\u2019s degree":
        return "Bachelor's Degree";
      case "master\u75f4 degree":
      case "master\u2019s degree":
        return "Master\u2019s Degree";
      case "trade/technical/vocational training":
        return "Trade / Technical / Vocational Training";
      case "i'd prefer not to answer":
      case "i\u2019d prefer not to answer":
        return "I'd Prefer Not To Answer";
      default:
        str = str.replaceAll("_", " ");
        return str.replace(/\w*/g, (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }
  }

  private rowVarianceFilter(value: EIRCrossAnalysis) {
    if (value.columns.length < 2) {
      return false;
    }
    const maxValue = Math.max(...value.columns.map((col) => col.average_hourly_rate));
    const minValue = Math.min(...value.columns.map((col) => col.average_hourly_rate));
    const variance = (maxValue - minValue) / maxValue;
    if (variance < 0.05) {
      return false;
    }
    return true;
  }

  private sortFunction(a: string, b: string): number {
    const customColumns = ["No", "Yes", "I'd prefer not to answer"];
    if (customColumns.indexOf(a) > -1 && customColumns.indexOf(b) > -1) {
      return customColumns.indexOf(a) - customColumns.indexOf(b);
    }

    return a.localeCompare(b);
  }
}
