















import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import ReportDataTableComponent from './ReportDataTableComponent.vue';
import * as _ from 'lodash';
import { ReportDataTable, TotalsAggregate, ImpactTargetsMeta, ReportDataTableRow } from '@/store/models';
import { EmptyReportDataTable } from '@/store/models-empty';
import {formatCurrencyToString} from "@/lib/shared";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class CCABBusinessDevelopmentComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public yearlyAggregates: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private isLoaded: boolean = false;
  private categoryTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);

  private socialProcurementTarget = 0;

  protected mounted() {
    this.getTargets();
    this.refreshProcurementTable();
    this.isLoaded = true;
  }

  private refreshProcurementTable(): void {
    const yearlyTotalsAggregate = this.yearlyAggregates as { [key: number]: TotalsAggregate; };

    this.categoryTable.headers = this.reportHelper.yearlySocialCategoryHeaders;
    this.categoryTable.parentsBold = false;
    this.categoryTable.title = "Indigenous Procurement Performance";

    let serviceTotal = 0;
    let goodsTotal = 0;
    let rentalTotal = 0;
    const expenseYears = Object.keys(yearlyTotalsAggregate).map(Number).sort((n1, n2) => n2 - n1);
    for (const year of expenseYears) {
      const expenseCategories = yearlyTotalsAggregate[year].socialProcurement.expenseDiversityCategories.esbIndigenous;

      const categoryRowData: string[] = [];
      categoryRowData.push(year === new Date().getFullYear() ? "YTD" : year.toString());

      // Indigenous Service Provider
      categoryRowData.push(expenseCategories.services.valueFormatted);
      serviceTotal += expenseCategories.services.value;

      // Indigenous Goods Provider
      categoryRowData.push(expenseCategories.goods.valueFormatted);
      goodsTotal += expenseCategories.goods.value;

      // Indigenous Rental Provider
      categoryRowData.push(expenseCategories.equipment.valueFormatted);
      rentalTotal += expenseCategories.equipment.value;

      // Total
      categoryRowData.push(expenseCategories.total.valueFormatted);
      categoryRowData.push(`${this.socialProcurementTarget}%`);
      categoryRowData.push(`${expenseCategories.total.percentage.toFixed(1)}%`);

      this.categoryTable.rows.push({
        data: categoryRowData,
        dataValues: [],
        children: [],
        columnStyle: [],
      });
    }
    this.categoryTable.totalRow = {  data: [
      formatCurrencyToString(serviceTotal.toString()),
      formatCurrencyToString(goodsTotal.toString()),
      formatCurrencyToString(rentalTotal.toString()),
      formatCurrencyToString((serviceTotal + goodsTotal + rentalTotal).toString()),
      "",
      "",
    ] } as ReportDataTableRow;
  }

  private getTargets() {
    this.socialProcurementTarget = this.zeroIfUndefined((this.impactTargets as ImpactTargetsMeta).socialProcurement);
  }
}
