























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import * as _ from 'lodash';
import { ReportDataTable, TotalsAggregate } from '@/store/models';
import { addPercentageToReportDataTableRow } from '@/lib/shared';
import { ReportType } from './ReportHelper';

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class IndigenousProcurementVendorComponent extends BaseComponent {

  @Prop() public reportVendorDataTables: any;
  @Prop() public aggregate: any;
  @Prop() public reportMode: any;
  @Prop() public reportHelper: any;

  private isLoaded: boolean = false;

  get isCCABTypeReport(): boolean {
    return this.reportHelper.isVisible([ ReportType.SpecialityCanadianCouncil ]);
  }

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate && this.reportVendorDataTables) {
      // Fill in the percentages in the reportDataTables once we know the totalProjectExpenses from the other aggregate response.
      for (const reportDataTable of (this.reportVendorDataTables as ReportDataTable[])) {
        // Total Row.
        if (reportDataTable.totalRow) {
          if (reportDataTable.totalRow.data.length > 0) {
            reportDataTable.totalRow = addPercentageToReportDataTableRow(reportDataTable.totalRow, (this.aggregate as TotalsAggregate).totalProjectExpenses);
          }
        }
      }
      if (this.isCCABTypeReport) {
        // Remove bottom margin
        this.reportVendorDataTables[this.reportVendorDataTables.length - 1].className = "";
      }
    }
    this.isLoaded = true;
  }
}
