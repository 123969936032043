




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import * as _ from 'lodash';
import { getTargetLabelStyle, getTargetLineStyle, getTargetItemStyle, getTargetLineLeft, getTargetLineColor, getTargetScaleItemStyle, getTargetScaleItems } from '../lib/targets';
import { TargetDataTable } from '@/store/models';

@Component({
  components: {},
})
export default class TargetsTableComponent extends Vue {

  @Prop() public targetsDataTable: any;

  private getTargetLabelStyle = getTargetLabelStyle;
  private getTargetLineStyle = getTargetLineStyle;
  private getTargetItemStyle = getTargetItemStyle;
  private getTargetLineLeft = getTargetLineLeft;
  private getTargetLineColor = getTargetLineColor;
  private getTargetScaleItems = getTargetScaleItems;
  private getTargetScaleItemStyle = getTargetScaleItemStyle;

  private getTableClass(targetsDataTable: TargetDataTable): string {
    return (targetsDataTable.className !== '' ? targetsDataTable.className + ' ' : '' ) + 'target-table-container';
  }
}
