























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import TargetsTableComponent from './TargetsTableComponent.vue';
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import DiversityBreakdownComponent from "./DiversityBreakdownComponent.vue";
import { TotalsAggregate, CategoryAggregateTotalsValue, ReportDataTable, TargetDataTableItem, ResourceSearchResult, Aggregate, FacetDetailMeta, TotalsMeta, ExpensesTotalsMeta, ExpenseEmploymentTotalsMeta, Vendor, CommonMeta, TargetDataTable, ImpactTargetsMeta, ReportDataTableRow, ReportDataTableHeader } from '../store/models';
import { getTNBTotal } from '../lib/aggregate';
import { EmptyReportDataTable, EmptyTargetDataTable, EmptyTargetDataTableItem } from '../store/models-empty';
import * as _ from 'lodash';
import { getSortedTNBResults } from '@/lib/charts';
import { formatCurrencyToString } from '@/lib/shared';
import ReportHelper, { ReportType } from './ReportHelper';

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    DiversityBreakdownComponent,
    TargetsTableComponent,
  },
})
export default class IndigenousBenefitsReportComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public beneficiaryAggregate: any;
  @Prop() public clientAggregate: any;
  @Prop() public icprojectsAggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;
  @Prop() public hideTargets: any;
  @Prop() public enabledFeatures: any;

  private targetsDataTable: TargetDataTable = _.cloneDeep(EmptyTargetDataTable);
  private summaryTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private contributionsTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private contributionsByClientTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private indigenousProjectBenefitTarget: number = 0;
  private indigenousCommunityContributionTarget: number = 0;
  private indigenousEmploymentTarget: number = 0;
  private isLoaded: boolean = false;

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate) {
      // Target.
      this.getTargets();
      const totalsAggregate = (this.aggregate as TotalsAggregate);
      this.targetsDataTable.className = 'margin-bottom';
      const isProjectReportType: boolean = (this.reportHelper as ReportHelper).isProjectTypeReport();
      const isCommunityReportType: boolean = (this.reportHelper as ReportHelper).isCommunityTypeReport();
      const childTargets: TargetDataTableItem[] = [];
      childTargets.push({ title: 'Project Benefits', percentage: totalsAggregate.tnb.projectBenefits.indigenous.percentage, target: this.indigenousProjectBenefitTarget, color: '#f59c4d', children: [] });
      if (!isProjectReportType && !isCommunityReportType) {
        childTargets.push({ title: 'Community Contributions', percentage: totalsAggregate.tnb.communityContributions.indigenous.percentage, target: this.indigenousCommunityContributionTarget, color: '#f59c4d', children: [] });
      }
      if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
        childTargets.push({ title: 'Indigenous Employment', percentage: totalsAggregate.tnb.employment.indigenous.percentage, target: this.indigenousEmploymentTarget, color: '#f59c4d', children: [] });
      }
      this.targetsDataTable.targets.push({ title: 'Indigenous Benefits', children: childTargets, percentage: 0, target: 0, color: '' });
      // Indigenous Benefits summary data table.
      this.summaryTable.parentsBold = false;
      this.summaryTable.className = 'margin-bottom';
      this.summaryTable.headers = this.reportHelper.indigenousBenefitsSummaryHeaders;
      if (isProjectReportType || isCommunityReportType) {
        this.summaryTable.headers = this.summaryTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'community-contribution');
      }
      if (!this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
        this.summaryTable.headers = this.summaryTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'indigenous-employment');
      }
      const summaryRowData: string[] = [];
      summaryRowData.push(totalsAggregate.totalProjectRevenue.valueFormatted);
      summaryRowData.push(totalsAggregate.tnb.projectBenefits.indigenous.valueFormatted + ' (' + totalsAggregate.tnb.projectBenefits.indigenous.percentageFormatted + ')');
      if (!isProjectReportType && !isCommunityReportType) {
        summaryRowData.push(totalsAggregate.tnb.communityContributions.indigenous.valueFormatted + ' (' + totalsAggregate.tnb.communityContributions.indigenous.percentageFormatted + ')');
      }
      if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
        summaryRowData.push(totalsAggregate.tnb.employment.indigenous.valueFormatted + ' (' + totalsAggregate.tnb.employment.indigenous.percentageFormatted + ')');
      }
      summaryRowData.push(totalsAggregate.tnb.overall.indigenous.valueFormatted + ' (' + totalsAggregate.tnb.overall.indigenous.percentageFormatted + ')');
      this.summaryTable.rows.push({ data: summaryRowData,
                                    dataValues: [],
                                    children: [],
                                    columnStyle: [ { index: this.summaryTable.headers.length - 1, isBold: true, isItalic: false }] });
      this.summaryTable.totalRow = null;

      // Total Contributions by Community or Project data table.
      this.contributionsTable.parentsBold = false;
      this.contributionsTable.className = 'margin-bottom';
      this.contributionsTable.autoCalculateTotalRow = true;
      this.contributionsTable.title = "Total Contributions by " + (isCommunityReportType ? "Project" : "Community");
      this.contributionsTable.headers = this.reportHelper.contributionsByCommunityHeaders;
      if (isProjectReportType || isCommunityReportType) {
        this.contributionsTable.headers = this.contributionsTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'community-contribution');
      }
      if (!this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
        this.contributionsTable.headers = this.contributionsTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'indigenous-employment');
      }
      // Use the icproject aggregate for Community reports.
      const searchResults = isCommunityReportType ? (this.icprojectsAggregate as ResourceSearchResult)
                                                    : (this.beneficiaryAggregate as ResourceSearchResult);
      const sortedResults = getSortedTNBResults(searchResults, true, true, true);
      for (const result of sortedResults) {
        if (result.total > 0) {
          // Get the result from the search results.
          const searchResult = ((searchResults as ResourceSearchResult).searchResults.results as Aggregate[]).find((x: Aggregate) => (x.facetDetail as FacetDetailMeta).identifier === result.identifier);
          if (searchResult) {
            const expensesTotalsMeta = (searchResult.totals as TotalsMeta).expenses ? ((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta) : null;
            const expenseTotalsMeta = expensesTotalsMeta ? expensesTotalsMeta.expenseTotals : null;
            const employmentMeta = expensesTotalsMeta ? (expensesTotalsMeta.employment as ExpenseEmploymentTotalsMeta) : null;
            if (expenseTotalsMeta) {
              const contributionsRowData: string[] = [];
              contributionsRowData.push(searchResult.facetDetail.displayName);
              contributionsRowData.push(formatCurrencyToString(expenseTotalsMeta.totalIndigenousProjectContributions.toString()));
              if (!isProjectReportType && !isCommunityReportType) {
                contributionsRowData.push(formatCurrencyToString(expenseTotalsMeta.totalIndigenousCommunityContributions.toString()));
              }
              if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
                contributionsRowData.push(formatCurrencyToString((employmentMeta ? employmentMeta.totalIndigenousEmploymentValue : 0).toString()));
              }
              contributionsRowData.push(formatCurrencyToString(getTNBTotal(searchResult.totals as TotalsMeta, true).toString()));
              const contributionsRowDataValues: any[] = [];
              contributionsRowDataValues.push(null);
              contributionsRowDataValues.push(expenseTotalsMeta.totalIndigenousProjectContributions);
              if (!isProjectReportType && !isCommunityReportType) {
                contributionsRowDataValues.push(expenseTotalsMeta.totalIndigenousCommunityContributions);
              }
              contributionsRowDataValues.push((employmentMeta ? employmentMeta.totalIndigenousEmploymentValue : 0));
              contributionsRowDataValues.push(getTNBTotal(searchResult.totals as TotalsMeta, true));
              this.contributionsTable.rows.push({ data: contributionsRowData,
                                                  dataValues: contributionsRowDataValues,
                                                  children: [],
                                                  columnStyle: [ { index: this.contributionsTable.headers.length - 1, isBold: true, isItalic: false }] });
            }
          }
        }
      }

      if (this.clientAggregate) {
        // Total Contributions to Community by Client data table.
        this.contributionsByClientTable.parentsBold = false;
        this.contributionsByClientTable.className = 'margin-bottom';
        this.contributionsByClientTable.autoCalculateTotalRow = true;
        this.contributionsByClientTable.title = "Total Contributions to Community by Client";
        this.contributionsByClientTable.headers = this.reportHelper.contributionsByClientHeaders;
        if (!this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
          this.contributionsByClientTable.headers = this.contributionsByClientTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'indigenous-employment');
        }
        const clientSearchResults = (this.clientAggregate as ResourceSearchResult);
        const clientSortedResults = getSortedTNBResults(clientSearchResults, false, true, true);
        for (const result of clientSortedResults) {
          if (result.total > 0) {
            // Get the result from the search results.
            const searchResult = ((clientSearchResults as ResourceSearchResult).searchResults.results as Vendor[]).find((x: Vendor) => (x.common as CommonMeta).identifier === result.identifier);
            if (searchResult) {
              const expensesTotalsMeta = (searchResult.totals as TotalsMeta).expenses ? ((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta) : null;
              const expenseTotalsMeta = expensesTotalsMeta ? expensesTotalsMeta.expenseTotals : null;
              const employmentMeta = expensesTotalsMeta ? (expensesTotalsMeta.employment as ExpenseEmploymentTotalsMeta) : null;
              if (expenseTotalsMeta) {
                const projectBenefits: number = expenseTotalsMeta.totalIndigenousProjectContributions;
                const indigenousEmployment: number = (employmentMeta ? employmentMeta.totalIndigenousEmploymentValue : 0);
                const totalBenefit: number = projectBenefits + indigenousEmployment;
                const rowData: any[] = [ (searchResult.common as CommonMeta).displayName, formatCurrencyToString(projectBenefits.toString()) ];
                if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
                  rowData.push(formatCurrencyToString(indigenousEmployment.toString()));
                }
                rowData.push(formatCurrencyToString(totalBenefit.toString()));
                this.contributionsByClientTable.rows.push({ data: rowData,
                                                            dataValues: [ null,
                                                                          projectBenefits,
                                                                          indigenousEmployment,
                                                                          totalBenefit,
                                                                        ],
                                                            children: [],
                                                            columnStyle: [ { index: this.contributionsByClientTable.headers.length - 1, isBold: true, isItalic: false }] });
              }
            }
          }
        }
      }

      this.isLoaded = true;
    }
  }

  private getCategoryDataTableItem(type: string, item: CategoryAggregateTotalsValue): string[] {
    const dataTableItem: string[] = [];
    if (type !== '') {
      dataTableItem.push(type);
    }
    dataTableItem.push(item.goods.valueFormatted + ' (' + item.goods.percentageFormatted + ')');
    dataTableItem.push(item.services.valueFormatted + ' (' + item.services.percentageFormatted + ')');
    dataTableItem.push(item.equipment.valueFormatted + ' (' + item.equipment.percentageFormatted + ')');
    dataTableItem.push(item.unknown.valueFormatted + ' (' + item.unknown.percentageFormatted + ')');
    dataTableItem.push(item.total.valueFormatted + ' (' + item.total.percentageFormatted + ')');
    return dataTableItem;
  }

  private getTargets() {
    const impactTargets = this.impactTargets as ImpactTargetsMeta;
    this.indigenousCommunityContributionTarget = this.zeroIfUndefined(impactTargets.indigenousCommunityContribution);
    this.indigenousEmploymentTarget = this.zeroIfUndefined(impactTargets.indigenousEmployment);
    this.indigenousProjectBenefitTarget = this.zeroIfUndefined(impactTargets.indigenousProjectBenefit);
  }
}
