























import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import TargetComponent from "../components/TargetComponent.vue";
import { TotalsAggregate, CategoryAggregateTotalsValue, ReportDataTable, TotalsAggregateValue, TargetDataTableItem } from '../store/models';
import { formatTotalForDisplay } from '../lib/aggregate';
import { EmptyReportDataTable, EmptyReportDataTableRow, EmptyTargetDataTableItem } from '../store/models-empty';
import * as _ from 'lodash';
import ReportHelper from './ReportHelper';

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class SocialProcurementReportComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private targetItem: TargetDataTableItem = _.cloneDeep(EmptyTargetDataTableItem);
  // private summaryTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  // private categoryTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private impactTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);

  private reportDataTables: ReportDataTable[] = [];

  private isLoaded: boolean = false;

  protected mounted() {
    if (this.aggregate) {
      this.refreshTargets();
      this.refreshImpactTable();
      this.isLoaded = true;
    }
  }

  private refreshImpactTable() {
    const totalsAggregate = (this.aggregate as TotalsAggregate);
    const isProjectReport = (this.reportHelper as ReportHelper).isProjectTypeReport();

    this.impactTable.title = "Impact Procurement";
    this.impactTable.className = "margin-bottom margin-top";
    this.impactTable.headers = this.reportHelper.indigneousProcurementSummaryHeaders;
    this.impactTable.parentsBold = false;
    this.impactTable.totalRow = null;

    // If this is included in  a none project report we should probably use totalSpend instead of totalProjectExpense
    this.impactTable.rows.push({data: [isProjectReport ? "Total Project Procurement" : "Total Procurement", totalsAggregate.totalProjectExpenses.valueFormatted], children: []});
    this.impactTable.rows.push({
      data: [
        "Social Procurement", totalsAggregate.socialProcurement.overall.valueFormatted + " (" + totalsAggregate.socialProcurement.overall.percentageFormatted + ")",
      ],
      children: [],
    });
    this.impactTable.rows.push({
      data: [
        "Indigenous Procurement", totalsAggregate.indigenous.directIndigenousSpend.valueFormatted + " (" + totalsAggregate.indigenous.directIndigenousSpend.percentageFormatted + ")",
      ],
      children: [],
    });
  }

  private refreshTargets() {
    const totalsAggregate = (this.aggregate as TotalsAggregate);

    this.targetItem.title = "Social Procurement";
    this.targetItem.target = this.impactTargets ? this.zeroIfUndefined(this.impactTargets.socialProcurement) : 0;
    this.targetItem.percentage = totalsAggregate.socialProcurement.overall.percentage;
  }

  // private refreshCharts() {
  //   if (this.aggregate) {
  //     const totalsAggregate = (this.aggregate as TotalsAggregate);

  //     // Target.
  //     this.targetItem.title = "Social Procurement";
  //     this.targetItem.target = this.impactTargets ? this.zeroIfUndefined(this.impactTargets.socialProcurement) : 0;
  //     this.targetItem.percentage = totalsAggregate.socialProcurement.overall.percentage;

  //     // Social procurement summary data table.
  //     this.summaryTable.parentsBold = true;
  //     this.summaryTable.headers = this.reportHelper.socialSummaryHeaders;
  //     this.summaryTable.rows.push({ data: ['Total Project Expenses', formatTotalForDisplay(totalsAggregate.totalProjectExpenses), ''], children: [] });
  //     this.summaryTable.rows.push({ data: ['Social Procurement', formatTotalForDisplay(totalsAggregate.socialProcurement.overall), totalsAggregate.socialProcurement.overall.percentageFormatted], children: [] });
  //     this.summaryTable.rows.push({ data: ['Certified Equity Seeking Businesses', '', ''],
  //                               children: [ { data: ['Certified Indigenous Businesses', formatTotalForDisplay(totalsAggregate.socialProcurement.esbIndigenous), totalsAggregate.socialProcurement.esbIndigenous.percentageFormatted ], children: [] },
  //                                           { data: ['Certified Equity Seeking (Less Indigenous) Businesses', formatTotalForDisplay(totalsAggregate.socialProcurement.esbDiverse), totalsAggregate.socialProcurement.esbDiverse.percentageFormatted ], children: [] } ],
  //                           });
  //     this.summaryTable.rows.push({ data: ['Self Declared Equity Seeking Businesses'],
  //                               children: [ { data: ['Self Declared Indigenous Businesses', formatTotalForDisplay(totalsAggregate.socialProcurement.sociallyFocusedSpend), totalsAggregate.socialProcurement.sociallyFocusedSpend.percentageFormatted ], children: [] },
  //           /* TODO: What is this - new? */ { data: ['Self Declared Equity Seeking (Less Indigenous) Business', formatTotalForDisplay(totalsAggregate.socialProcurement.sociallyFocusedSpend), totalsAggregate.socialProcurement.sociallyFocusedSpend.percentageFormatted ], children: [] } ],
  //                           });
  //     /* TODO: Where do i get this data from ? */
  //     /*
  //     summaryTable.rows.push({ data: ['Indigenous Community'],
  //                               children: [ { data: ['Indigenous Community Owned Businesses', totalsAggregate.socialProcurement.esbIndigenous.valueFormatted.replace('$', ''), totalsAggregate.socialProcurement.esbIndigenous.percentageFormatted ], children: [] },
  //                                           { data: ['Indigenous Community Relationships', totalsAggregate.socialProcurement.esbDiverse.valueFormatted.replace('$', ''), totalsAggregate.socialProcurement.esbDiverse.percentageFormatted ], children: [] } ],
  //                           });
  //     */
  //     this.summaryTable.rows.push({ data: ['Social Purpose Organizations', formatTotalForDisplay(totalsAggregate.socialProcurement.thirdPartyCertifiedSpend), totalsAggregate.socialProcurement.thirdPartyCertifiedSpend.percentageFormatted ], children: [] });
  //     this.summaryTable.rows.push({ data: ['Local', formatTotalForDisplay(totalsAggregate.socialProcurement.localSpend), totalsAggregate.socialProcurement.localSpend.percentageFormatted ], children: [] });
  //     this.summaryTable.rows.push({ data: ['Small Enterprise Spend', formatTotalForDisplay(totalsAggregate.socialProcurement.smeSpend), totalsAggregate.socialProcurement.smeSpend.percentageFormatted ], children: [] });

  //     this.summaryTable.totalRow = null;

  //     // Social procurement category data table.
  //     this.categoryTable.headers = this.reportHelper.socialCategoryHeaders;
  //     this.categoryTable.parentsBold = false;

  //     const expenseCategories = totalsAggregate.socialProcurement.expenseDiversityCategories;

  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Certified Indigenous Business', expenseCategories.esbIndigenous), children: [] });
  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Certified Equity Seeking Business (Less Indigenous)', expenseCategories.esbDiverse), children: [] });
  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Social Purpose Organizations', expenseCategories.thirdPartyCertifiedSpend), children: [] });
  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Self-Declared Equity Seeking Business', expenseCategories.sociallyFocusedSpend), children: [] });
  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Local Spend', expenseCategories.localSpend), children: [] });
  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Small Enterprise Spend', expenseCategories.smeSpend), children: [] });
  //     this.categoryTable.rows.push( { data: this.getCategoryDataTableItem('Not Diverse / Unknown', expenseCategories.unknown), children: [] });

  //     this.categoryTable.totalRow = _.cloneDeep(EmptyReportDataTableRow);
  //     this.categoryTable.totalRow.data = this.getCategoryDataTableItem('', expenseCategories.overall);

  //     this.isLoaded = true;
  //   }
  // }

  // private formatTotalForDisplay(total: TotalsAggregateValue): string {
  //   if (total.value === 0) {
  //     return '-';
  //   } else {
  //     return total.valueFormatted.replace('$', '');
  //   }
  // }

  // private getCategoryDataTableItem(type: string, item: CategoryAggregateTotalsValue): string[] {
  //   const dataTableItem: string[] = [];
  //   if (type !== '') {
  //     dataTableItem.push(type);
  //   }
  //   dataTableItem.push(item.goods.valueFormatted + ' (' + item.goods.percentageFormatted + ')');
  //   dataTableItem.push(item.services.valueFormatted + ' (' + item.services.percentageFormatted + ')');
  //   dataTableItem.push(item.equipment.valueFormatted + ' (' + item.equipment.percentageFormatted + ')');
  //   dataTableItem.push(item.unknown.valueFormatted + ' (' + item.unknown.percentageFormatted + ')');
  //   dataTableItem.push(item.total.valueFormatted + ' (' + item.total.percentageFormatted + ')');
  //   return dataTableItem;
  // }
}
