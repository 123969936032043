














import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import SpinnerComponent from './SpinnerComponent.vue';
import TargetsTableComponent from './TargetsTableComponent.vue';
import * as _ from 'lodash';
import { ImpactTargetsMeta, TargetDataTable, TotalsAggregate } from '@/store/models';
import { EmptyTargetDataTable } from '@/store/models-empty';

@Component({
  components: {
    SpinnerComponent,
    TargetsTableComponent,
  },
})
export default class SummaryTargetsComponent extends BaseComponent {

  @Prop() public aggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public projectId: any;
  @Prop() public reportMode: any;
  @Prop() public enabledFeatures: any;

  private isLoaded: boolean = false;

  private socialTarget: number = 0;
  private diverseProjectBenefitTarget: number = 0;
  private indigenousProjectBenefitTarget: number = 0;
  private indigenousCommunityContributionTarget: number = 0;
  private diverseCommunityContributionTarget: number = 0;
  private indigenousEmploymentTarget: number = 0;
  private diverseEmploymentTarget: number = 0;

  private targetsDataTable: TargetDataTable = _.cloneDeep(EmptyTargetDataTable);

  protected mounted() {
    this.refreshCharts();
  }

  private refreshCharts() {
    if (this.aggregate) {
      this.getTargets();
      const totalsAggregate = (this.aggregate as TotalsAggregate);
      this.targetsDataTable.isFixedTargetLine = true;
      this.targetsDataTable.targets.push({ title: 'Social Procurement', children: [], percentage: totalsAggregate.socialProcurement.overall.percentage, target: this.socialTarget, color: '#f59c4d' });
      const targetChildren: any[] = [ { title: 'Project Benefits', percentage: totalsAggregate.tnb.projectBenefits.indigenous.percentage, target: this.indigenousProjectBenefitTarget, color: '#f59c4d', children: [] },
                                      { title: 'Community Contributions', percentage: totalsAggregate.tnb.communityContributions.indigenous.percentage, target: this.indigenousCommunityContributionTarget, color: '#f59c4d', children: [] } ];
      if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
        targetChildren.push ({ title: 'Indigenous Employment', percentage: totalsAggregate.tnb.employment.indigenous.percentage, target: this.indigenousEmploymentTarget, color: '#f59c4d', children: [] });
      }
      this.targetsDataTable.targets.push({ title: 'Indigenous Benefits', children: targetChildren, percentage: 0, target: 0, color: '' });
      this.isLoaded = true;
    }
  }

  private getTargets() {
    const impactTargets = this.impactTargets as ImpactTargetsMeta;
    this.socialTarget = this.impactTargets ? this.zeroIfUndefined(this.impactTargets.socialProcurement) : 0;
    this.indigenousCommunityContributionTarget = this.zeroIfUndefined(impactTargets.indigenousCommunityContribution);
    this.diverseCommunityContributionTarget = this.zeroIfUndefined(impactTargets.diverseCommunityContribution);
    this.indigenousEmploymentTarget = this.zeroIfUndefined(impactTargets.indigenousEmployment);
    this.diverseEmploymentTarget = this.zeroIfUndefined(impactTargets.diverseEmployment);
    this.indigenousProjectBenefitTarget = this.zeroIfUndefined(impactTargets.indigenousProjectBenefit);
    this.diverseProjectBenefitTarget = this.zeroIfUndefined(impactTargets.diverseProjectBenefit);
  }
}
