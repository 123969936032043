




















import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import TargetComponent from "../components/TargetComponent.vue";
import { ReportDataTable, ExpenseAggregate, ShortRecord, Vendor, CommonMeta, Project, AffiliatedVendorsMeta, DiversityReferenceData } from "../store/models";
import { EmptyReportDataTable } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper from "./ReportHelper";
import { formatCurrencyToString } from "@/lib/shared";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class SocialProcurementReportComponent extends BaseComponent {
  @Prop() public expenseData!: ExpenseAggregate[];
  @Prop() public projectExpenseData!: { [key: string]: ExpenseAggregate[] };
  @Prop() public projectServicesExpenseData!: { [key: string]: ExpenseAggregate[] };
  @Prop() public projectGoodsExpenseData!: { [key: string]: ExpenseAggregate[] };
  @Prop() public projectEquipmentExpenseData!: { [key: string]: ExpenseAggregate[] };
  @Prop() public projectContributionExpenseData!: { [key: string]: ExpenseAggregate[] };
  @Prop() public projectRevenueExpenseData!: { [key: string]: ExpenseAggregate[] };
  @Prop() public diversityReferenceData!: DiversityReferenceData;

  @Prop() public projectDetails!: Record<string, Project>;
  @Prop() public payeeDetails!: Record<string, Vendor>;

  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private summaryTables: ReportDataTable[] = [];
  private payeeTables: ReportDataTable[] = [];
  private contributionTables: ReportDataTable[] = [];
  private isLoaded: boolean = false;

  private projects: Array<{ identifier: string; name: string }> = [];

  protected mounted() {
    this.parseProjects();
    this.createSummaryTables();
    this.createPayeeTables();
    this.createContributionTables();
    this.isLoaded = true;
  }

  private parseProjects() {
    for (const agg of this.expenseData) {
      this.projects.push({ identifier: agg.identifier, name: agg.identifier });
    }
  }

  private createSummaryTables() {
    for (const project of this.projects) {
      const summaryTable = _.cloneDeep(EmptyReportDataTable);
      summaryTable.className = "margin-bottom";
      summaryTable.headers = this.reportHelper.locationProjectsSummaryHeaders;
      summaryTable.parentsBold = false;
      summaryTable.totalRow = null;

      let revenue = 0;
      let expenses = 0;
      let social = 0;
      let indigenous = 0;
      let diverseValue = 0;
      let value = 0;

      revenue = this.projectRevenueExpenseData[project.identifier]
        ? this.projectRevenueExpenseData[project.identifier][0]
          ? this.projectRevenueExpenseData[project.identifier][0].payload.expenses.expenseTotals.totalSpend
          : 0
        : 0;

      for (const expenseAgg of this.projectExpenseData[project.identifier]) {
        expenses += expenseAgg.payload.expenses.expenseTotals.totalSpend;
        social += this.getSocialProcurement(expenseAgg);
        indigenous += this.getTNBTotal(expenseAgg);
        diverseValue += expenseAgg.payload.expenses.employment.totalDiverseEmploymentValue;
        value += expenseAgg.payload.expenses.employment.totalEmploymentValue;
      }
      let employment = (diverseValue / value) * 100;
      if (isNaN(employment)) {
        employment = 0;
      }

      const projectName = this.projectDetails[project.identifier] ? (this.projectDetails[project.identifier].common ? (this.projectDetails[project.identifier].common as CommonMeta).displayName : "") : "";

      summaryTable.rows.push({
        data: [
          projectName,
          formatCurrencyToString(revenue.toFixed(2), false),
          formatCurrencyToString(expenses.toFixed(2), false),
          formatCurrencyToString(social.toFixed(2), false),
          formatCurrencyToString(indigenous.toFixed(2), false),
          employment.toFixed(2) + "%",
        ],
        children: [],
      });
      this.summaryTables.push(summaryTable);
    }
  }

  private createPayeeTables() {
    for (const project of this.projects) {
      const payeeTable = _.cloneDeep(EmptyReportDataTable);
      payeeTable.className = "margin-bottom";
      payeeTable.headers = this.reportHelper.locationProjectsPayeeHeaders;
      payeeTable.parentsBold = false;
      payeeTable.totalRow = null;

      let total = 0;

      total += this.addPayeeRows(payeeTable, "Service", project.identifier, this.projectServicesExpenseData[project.identifier]);
      total += this.addPayeeRows(payeeTable, "Goods", project.identifier, this.projectGoodsExpenseData[project.identifier]);
      total += this.addPayeeRows(payeeTable, "Equipment Rental", project.identifier, this.projectEquipmentExpenseData[project.identifier]);

      payeeTable.totalRow = {
        data: ["", formatCurrencyToString(total.toFixed(2), false)],
        children: [],
      };
      this.payeeTables.push(payeeTable);
    }
  }

  private addPayeeRows(payeeTable: ReportDataTable, type: string, project: string, expenses: ExpenseAggregate[]) {
    let total = 0;

    for (const agg of expenses) {
      total += agg.payload.expenses.expenseTotals.totalSpend;
      const payeeDetails = this.getPayeeDetails(agg.identifier, project);

      payeeTable.rows.push({
        data: [
          payeeDetails.displayName,
          type,
          formatCurrencyToString(agg.payload.expenses.expenseTotals.totalSpend.toFixed(2), false),
          payeeDetails.local ? "Yes" : "",
          payeeDetails.indigenousRelationship,
          payeeDetails.indigenousOwnership,
          payeeDetails.diversityClass,
        ],
        children: [],
      });
    }

    return total;
  }

  private createContributionTables() {
    for (const project of this.projects) {
      const contributionTable = _.cloneDeep(EmptyReportDataTable);
      contributionTable.title = "Contributions";
      contributionTable.className = "margin-bottom";
      contributionTable.headers = this.reportHelper.locationProjectsContributionHeaders;
      contributionTable.parentsBold = false;

      let total = 0;

      for (const agg of this.projectContributionExpenseData[project.identifier]) {
        total += agg.payload.expenses.expenseTotals.totalSpend;
        const payeeDetails = this.getPayeeDetails(agg.identifier, project.identifier);
        contributionTable.rows.push({
          data: [payeeDetails.displayName, formatCurrencyToString(agg.payload.expenses.expenseTotals.totalSpend.toFixed(2), false), payeeDetails.indigenousOwnership, payeeDetails.diversityClass],
          children: [],
        });
      }

      contributionTable.totalRow = {
        data: [formatCurrencyToString(total.toFixed(2), false)],
        children: [],
      };
      this.contributionTables.push(contributionTable);
    }
  }

  private getPayeeDetails(payee: string, project: string): { displayName: string; diversityClass: string; indigenousRelationship: string; indigenousOwnership: string; local: boolean } {
    if (!this.payeeDetails[payee]) {
      return {
        displayName: payee,
        diversityClass: "",
        indigenousRelationship: "",
        indigenousOwnership: "",
        local: false,
      };
    }

    let local = false;
    let indigenousRelationship = this.payeeDetails[payee].vendor.proxyPayeeFor ? ((this.payeeDetails[payee].vendor.proxyPayeeFor as ShortRecord).displayName as string) : "";
    const indigenousOwnership = this.payeeDetails[payee].vendor.proxyPayeeFor ? ((this.payeeDetails[payee].vendor.proxyPayeeFor as ShortRecord).displayName as string) : "";
    const diversityClass = this.reportHelper.getSupplyChainDiversityDescription(this.payeeDetails[payee].diversityDeclarations);
    const displayName = this.payeeDetails[payee].common ? ((this.payeeDetails[payee].common as CommonMeta).displayName as string) : "";

    if (this.projectDetails[project] && this.projectDetails[project].affiliatedVendors) {
      const affilliatedVendor = (this.projectDetails[project].affiliatedVendors as AffiliatedVendorsMeta[]).find((vendor) => vendor.vendorId === payee);
      if (affilliatedVendor) {
        local = affilliatedVendor.isLocal ? affilliatedVendor.isLocal : false;
        indigenousRelationship = affilliatedVendor.partnerDisplayName ? affilliatedVendor.partnerDisplayName : "";
      }
    }

    return {
      displayName,
      diversityClass,
      indigenousRelationship,
      indigenousOwnership,
      local,
    };
  }

  private getSocialProcurement(expenseAgg: ExpenseAggregate): number {
    return expenseAgg
      ? expenseAgg.payload.expenses.socialProcurement.totalIndigenousSpend +
          expenseAgg.payload.expenses.socialProcurement.totalDiverseSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalLocalSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalSmeSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalSociallyFocusedSpend +
          expenseAgg.payload.expenses.socialProcurement.diversityDetail.totalThirdPartyCertifiedSpend
      : 0;
  }

  private getTNBTotal(expenseAgg: ExpenseAggregate): number {
    return expenseAgg
      ? expenseAgg.payload.expenses.expenseTotals.totalIndigenousCommunityContributions +
          expenseAgg.payload.expenses.expenseTotals.totalIndigenousProjectContributions +
          expenseAgg.payload.expenses.employment.totalIndigenousEmploymentValue
      : 0;
  }
}
