import { TargetDataTableItem, TargetScaleItem } from '../store/models';

const targetLineOffsetPercentage: number = 50;
const targetItemWidthPixels: number = 4;
const targetItemHeightPixels: number = 38;
const greenColor: string = '#54b0a4';
const orangeColor: string = '#f59c4d';

export function getTargetLabelStyle(containerPercentage: number): string {
    return 'left:' + ((targetLineOffsetPercentage * (containerPercentage / 100)) + (100 - containerPercentage) - 3) + '%';
}

export function getTargetLineStyle(containerPercentage: number, item: TargetDataTableItem|null = null): string {
    const scaleIncrement = item ? getScaleIncrement(item) : 0;
    return 'left:' + (((item ? (item.target / (scaleIncrement * 10)) * 100 : targetLineOffsetPercentage) * (containerPercentage / 100)) + (100 - containerPercentage)).toString() + '%';
}

export function getTargetItemStyle(item: TargetDataTableItem, heightPixels: number = targetItemHeightPixels, isSingleTarget: boolean = false): string {
    if (item.children.length === 0) {
        const leftPercentage: number = getTargetLineLeft(item, isSingleTarget);
        const color: string = getTargetLineColor(item);
        const width: number = targetItemWidthPixels;
        const marginLeft: number = 0;
        return 'left:' + leftPercentage.toString() + '%;' + 'background-color:' + color + ';width:' + width.toString() + 'px;' + 'height:' + heightPixels + 'px;' + 'margin-left:' + marginLeft.toString() + 'px';
    } else {
        return '';
    }
}

export function getTargetLineLeft(item: TargetDataTableItem, isSingleTarget: boolean = false): number {
    if (isSingleTarget) {
        const scaleIncrement = getScaleIncrement(item);
        return item.percentage / (scaleIncrement * 10) * 100;
    } else {
        return ((item.target * 2) <= item.percentage ? 1 : (item.percentage / item.target) / 2) * 100;
    }
}

export function getTargetLineColor(item: TargetDataTableItem): string {
    return item.percentage >= item.target ? greenColor : orangeColor;
}

export function getScaleIncrement(item: TargetDataTableItem): number {
    const scaleTotal = item.target > item.percentage ? (item.target * 2) : item.percentage * 2;
    const roundUpTo = (scaleTotal / 10);
    if (roundUpTo >= 5) {
        return 10;
    } else if (roundUpTo >= 2) {
        return 5;
    } else if (roundUpTo >= 1) {
        return 2;
    } else {
        return 1;
    }
}

export function getTargetScaleItems(item: TargetDataTableItem): TargetScaleItem[] {
    const scaleIncrement = getScaleIncrement(item);
    const targetScaleItems: TargetScaleItem[] = [];
    for (let x = 1; x <= 10; x++) {
        targetScaleItems.push({ percentage: x * scaleIncrement, index: x, percentageIncrement: scaleIncrement, totalPercentage: scaleIncrement * 10 });
    }
    return targetScaleItems;
}

export function getTargetScaleItemStyle(targetScaleItem: TargetScaleItem) {
    const leftPercentage: number = ((targetScaleItem.percentage - targetScaleItem.percentageIncrement) / targetScaleItem.totalPercentage) * 100;
    const widthPercentage: number = (targetScaleItem.percentageIncrement / targetScaleItem.totalPercentage) * 100;
    const offSet: string = (targetScaleItem.percentage === targetScaleItem.totalPercentage) ? '- 0px' : '+ 0px';
    return 'left: calc(' + leftPercentage.toString() + '% ' + offSet + ');' + 'border-bottom: none; border-right: none; border-width:1px;' + 'width: ' + widthPercentage + '%';
}
