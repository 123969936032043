


























































import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import * as _ from "lodash";
import { ColumnStyleItem, EidaReportData, EidaReportSection, EidaReportTableRow, Project, ReportDataTable } from "@/store/models";
import { EmptyReportDataTable } from "@/store/models-empty";
import * as datetime from "../lib/datetime";
import moment from "moment-timezone";

interface DataChunk {
  start: string;
  end: string;
  sections: {
    [sectionName: string]: {
      [tableName: string]: EidaReportTableRow[];
    };
  };
}

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
  },
})
export default class InclusionDevelopmentComponent extends BaseComponent {
  @Prop() public reportMode: any;
  @Prop() public reportHelper: any;
  @Prop() public organizationName: any;
  @Prop() public inclusionDevelopmentAgreementData!: EidaReportData;
  @Prop() public projectDetails!: { [key: string]: Project };
  @Prop() public dateTo!: string;

  private isLoaded: boolean = false;

  private yearlyTables: Array<{
    summaryTable: ReportDataTable;
    equityEmploymentTable: ReportDataTable;
    indigenousEmploymentTable: ReportDataTable;
    nonEquityTradesTable: ReportDataTable;
    equityTradesTable: ReportDataTable;
    indigenousTradesTable: ReportDataTable;
  }> = [];
  private ptdTables: {
    summaryTable: ReportDataTable;
    equityEmploymentTable: ReportDataTable;
    indigenousEmploymentTable: ReportDataTable;
    nonEquityTradesTable: ReportDataTable;
    equityTradesTable: ReportDataTable;
    indigenousTradesTable: ReportDataTable;
  } = {
    summaryTable: _.cloneDeep(EmptyReportDataTable),
    equityEmploymentTable: _.cloneDeep(EmptyReportDataTable),
    indigenousEmploymentTable: _.cloneDeep(EmptyReportDataTable),
    nonEquityTradesTable: _.cloneDeep(EmptyReportDataTable),
    equityTradesTable: _.cloneDeep(EmptyReportDataTable),
    indigenousTradesTable: _.cloneDeep(EmptyReportDataTable),
  };

  private rowDateFormat: string = "MMM-YY";

  protected mounted() {
    this.createPtdTables();
    const yearlyData = this.createYearlyData();
    for (const dataChunk of yearlyData) {
      this.yearlyTables.push({
        summaryTable: this.createSummaryTable(dataChunk),
        equityEmploymentTable: this.createEquityEmploymentTable(dataChunk),
        indigenousEmploymentTable: this.createIndigenousEmploymentTable(dataChunk),
        nonEquityTradesTable: this.createNonEquityTradesTable(dataChunk),
        equityTradesTable: this.createEquityTradesTable(dataChunk),
        indigenousTradesTable: this.createIndigenousTradesTable(dataChunk),
      });
    }

    this.isLoaded = true;
  }

  get project(): Project {
    return Object.values(this.projectDetails)[0];
  }

  get today(): string {
    if (this.dateTo !== "") {
      return datetime.formatDateForDisplay(this.dateTo);
    }
    return datetime.formatDateForDisplay(new Date().toDateString());
  }

  get projectStartDate(): string {
    return this.project.project.startDate;
  }

  private createYearlyData() {
    const startDate = moment(this.projectStartDate); // Get the project start date as a moment object
    const reportSections = this.inclusionDevelopmentAgreementData.sections;
    const yearlyData: DataChunk[] = [];

    let currentStartDate = startDate.clone();
    let currentEndDate = currentStartDate.clone().add(11, "months").endOf("month"); // Define the first 12-month period

    while (true) {
      const chunk: {
        start: string;
        end: string;
        sections: {
          [sectionName: string]: {
            [tableName: string]: EidaReportTableRow[];
          };
        };
      } = {
        start: currentStartDate.format("YYYY-MM-DD"),
        end: currentEndDate.format("YYYY-MM-DD"),
        sections: {},
      };

      let hasData = false;

      // Iterate through each section
      for (const section of reportSections) {
        const sectionTables: {
          [tableName: string]: EidaReportTableRow[];
        } = {};

        // Iterate through tables in the section
        for (const tableKey of Object.keys(section.tables)) {
          const tableRows = section.tables[tableKey];

          // Filter rows within the current 12-month range
          const rowsInRange = tableRows.filter((row) => {
            const rowDate = moment(row.month);
            return rowDate.isBetween(currentStartDate, currentEndDate, "month", "[]");
          });

          // Add rows to the table data if any rows are found
          if (rowsInRange.length > 0) {
            sectionTables[tableKey] = rowsInRange;
            hasData = true; // Mark that this chunk contains data
          }
        }

        // If the section has data for this chunk, add its tables to the chunk
        if (Object.keys(sectionTables).length > 0) {
          chunk.sections[section.sectionName] = sectionTables;
        }
      }

      // Add the chunk to yearlyData if it contains data
      if (hasData) {
        yearlyData.push(chunk);
      }

      // Move to the next 12-month period
      currentStartDate = currentEndDate.clone().add(1, "day").startOf("month");
      currentEndDate = currentStartDate.clone().add(11, "months").endOf("month");

      // Stop if no data is left in any section or table
      const remainingData = reportSections.some((section) =>
        Object.values(section.tables).some((tableRows) =>
          tableRows.some((row) => {
            const rowDate = moment(row.month);
            return rowDate.isSameOrAfter(currentStartDate, "month");
          }),
        ),
      );
      if (!remainingData) {
        break;
      }
    }

    return yearlyData;
  }

  private createPtdTables() {
    const pillarASectionData = this.inclusionDevelopmentAgreementData.sections.find((section) => section.sectionName === "allEmployees") as EidaReportSection;
    const pillarBSectionData = this.inclusionDevelopmentAgreementData.sections.find((section) => section.sectionName === "apprenticesAndCertifiedTradespeople") as EidaReportSection;

    // Project Total
    const totalData = pillarASectionData.tables["eidaTotal"];
    this.ptdTables.summaryTable = this.createPtdTable(totalData, null, false, false, true);

    // Equity Employment
    const equityData = pillarASectionData.tables["eidaDiverse"];
    this.ptdTables.equityEmploymentTable = this.createPtdTable(equityData, 0.15, true, false, false);

    // Indigenous Employment
    const indigenousData = pillarASectionData.tables["eidaIndigenous"];
    this.ptdTables.indigenousEmploymentTable = this.createPtdTable(indigenousData, 0.06, true, false, false);

    // Non-Equity Trades
    const nonEquityData = pillarBSectionData.tables["apprenticeEidaNonDiverse"];
    this.ptdTables.nonEquityTradesTable = this.createPtdTable(nonEquityData, null, true, false, false);

    // Equity Trades
    const equityTradesData = pillarBSectionData.tables["apprenticeEidaDiverse"];
    this.ptdTables.equityTradesTable = this.createPtdTable(equityTradesData, 0.25, true, true, false);

    // Indigenous Trades
    const indigenousTradesData = pillarBSectionData.tables["apprenticeEidaIndigenous"];
    this.ptdTables.indigenousTradesTable = this.createPtdTable(indigenousTradesData, 0.03, true, false, false);
  }

  private createSummaryTable(dataChunk: DataChunk) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    table.title = "Project Total";

    const sectionData = dataChunk.sections["allEmployees"];
    const tableData = sectionData["eidaTotal"];

    if (tableData) {
      this.populateTable(table, tableData, null, "", false, false, true);
    }

    return table;
  }

  private createEquityEmploymentTable(dataChunk: DataChunk) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    table.title = "Equity Seeking";

    const sectionData = dataChunk.sections["allEmployees"];
    const tableData = sectionData["eidaDiverse"];

    if (tableData) {
      this.populateTable(table, tableData, 0.15, "", true, false, false);
    }

    return table;
  }

  private createIndigenousEmploymentTable(dataChunk: DataChunk) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    table.title = "Indigenous";

    const sectionData = dataChunk.sections["allEmployees"];
    const tableData = sectionData["eidaIndigenous"];

    if (tableData) {
      this.populateTable(table, tableData, 0.06, "", true, false, false);
    }

    return table;
  }

  private createNonEquityTradesTable(dataChunk: DataChunk) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    table.title = "Non Equity Seeking";

    const sectionData = dataChunk.sections["apprenticesAndCertifiedTradespeople"];
    const tableData = sectionData["apprenticeEidaNonDiverse"];

    if (tableData) {
      this.populateTable(table, tableData, null, "", true, false, false);
    }

    return table;
  }

  private createEquityTradesTable(dataChunk: DataChunk) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    table.title = "Equity Seeking";

    const sectionData = dataChunk.sections["apprenticesAndCertifiedTradespeople"];
    const tableData = sectionData["apprenticeEidaDiverse"];

    if (tableData) {
      this.populateTable(table, tableData, 0.25, "", true, true, false);
    }

    return table;
  }

  private createIndigenousTradesTable(dataChunk: DataChunk) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    table.title = "Indigenous";

    const sectionData = dataChunk.sections["apprenticesAndCertifiedTradespeople"];
    const tableData = sectionData["apprenticeEidaIndigenous"];

    if (tableData) {
      this.populateTable(table, tableData, 0.03, "12% of 25%	", true, false, false);
    }

    return table;
  }

  private populateTable(
    table: ReportDataTable,
    rowData: EidaReportTableRow[],
    target: number | null = null,
    altTargetText: string = "",
    includePercentAchieved: boolean = true,
    includeOnePointOneFactor: boolean = false,
    includeMonth: boolean = false,
  ) {
    table.className = "margin-bottom";
    table.parentsBold = false;
    table.headers = [
      // { text: "Month", rowLayout: 1, align: "left" },
      { text: "Off-Site", rowLayout: 1, align: "left" },
      { text: "On-Site", rowLayout: 1, align: "left" },
      { text: "Total", rowLayout: 1, align: "left" },
      // { text: "% Achieved", rowLayout: 1, align: "left" },
      // { text: "1.1 Factor", rowLayout: 1, align: "left" },
    ] as any[];
    table.totalRow = null;

    if (includeMonth) {
      table.headers.unshift({ text: "Month", rowLayout: 1, align: "left" } as any);
    }
    if (includeOnePointOneFactor) {
      table.headers.push({ text: "1.1 Factor", rowLayout: 1, align: "left" } as any);
    }
    if (includePercentAchieved) {
      table.headers.push({ text: "% Achieved", rowLayout: 1, align: "left" } as any);
    }

    const totalSectionData = this.inclusionDevelopmentAgreementData.sections.find((section) => section.sectionName === "allEmployees") as EidaReportSection;
    const totalTableData = totalSectionData.tables["eidaTotal"];

    let offsiteHours = 0;
    let onsiteHours = 0;
    let totalHours = 0;

    for (const row of rowData) {
      offsiteHours += row.off_site_man_hours;
      onsiteHours += row.on_site_man_hours;
      totalHours += row.total_man_hours;
      const month = moment(row.month);
      let targetMet = false;

      const rowValues: any[] = [];

      // Month
      if (includeMonth) {
        rowValues.push(month.format(this.rowDateFormat));
      }

      // Off-Site, On-Site, Total
      rowValues.push(row.off_site_man_hours ? row.off_site_man_hours.toFixed(1) : "-", row.on_site_man_hours ? row.on_site_man_hours.toFixed(1) : "-", row.total_man_hours ? row.total_man_hours.toFixed(1) : "-");

      // 1.1 Factor
      if (includeOnePointOneFactor) {
        rowValues.push(row.total_man_hours ? (row.total_man_hours * 1.1).toFixed(1) : "-");
      }

      // % Achieved
      if (includePercentAchieved) {
        const monthTotal = totalTableData.find((totalRow) => totalRow.month === row.month) as EidaReportTableRow;
        let percentAchieved = 0;

        if (includeOnePointOneFactor && row.total_man_hours) {
          percentAchieved = (row.total_man_hours * 1.1) / monthTotal.total_man_hours;
        } else if (row.total_man_hours) {
          percentAchieved = row.total_man_hours / monthTotal.total_man_hours;
        }
        if (target && percentAchieved >= target) {
          targetMet = true;
        }
        rowValues.push(percentAchieved ? (percentAchieved * 100).toFixed(1) + "%" : "-");
      }

      table.rows.push({
        data: rowValues,
        children: [],
        columnStyle: targetMet ? [{ index: includeOnePointOneFactor ? 4 : 3, textColour: "green" }] : [],
      });
    }

    // Total Row
    const totalRowData: any[] = [];
    if (includeMonth) {
      totalRowData.push("YTD");
    }
    totalRowData.push(offsiteHours ? offsiteHours.toFixed(1) : "-", onsiteHours ? onsiteHours.toFixed(1) : "-", totalHours ? totalHours.toFixed(1) : "-");

    if (includeOnePointOneFactor) {
      totalRowData.push(totalHours ? (totalHours * 1.1).toFixed(1) : "-");
    }

    let totalTargetMet = false;
    if (includePercentAchieved) {
      const grandTotal = totalTableData.map((row) => row.total_man_hours).reduce((a, b) => a + b, 0);

      let percentAchieved = 0;
      if (includeOnePointOneFactor && totalHours) {
        percentAchieved = (totalHours * 1.1) / grandTotal;
      } else if (totalHours) {
        percentAchieved = totalHours / grandTotal;
      }

      if (target && percentAchieved >= target) {
        totalTargetMet = true;
      }

      totalRowData.push(percentAchieved ? (percentAchieved * 100).toFixed(1) + "%" : "-");
    }

    const totalColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
    ];
    if (includeOnePointOneFactor) {
      totalColumnStyle.push({ index: 4, isBold: true });
    }

    if (totalTargetMet) {
      if (includeOnePointOneFactor) {
        totalColumnStyle[4].textColour = "green";
      } else {
        totalColumnStyle[3].textColour = "green";
      }
    }

    table.rows.push({
      data: totalRowData,
      children: [],
      columnStyle: totalColumnStyle,
    });

    // Target Row
    const targetRowData: any[] = [];
    if (includeMonth) {
      targetRowData.push("Targets");
    } else {
      targetRowData.push("");
    }

    if (includeOnePointOneFactor) {
      targetRowData.push("");
    }

    targetRowData.push(altTargetText, "", target ? (target * 100).toString() + "%" : "-");

    const targetColumnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
    ];

    if (includeOnePointOneFactor) {
      targetColumnStyle.push({ index: 4, isBold: true });
    }

    table.rows.push({
      data: targetRowData,
      children: [],
      columnStyle: targetColumnStyle,
    });
  }

  private createPtdTable(tableData: EidaReportTableRow[], target: number | null = null, includePercentAchieved: boolean = true, includeOnePointOneFactor: boolean = false, includeMonth: boolean = false) {
    const table: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
    const headers = [
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
      { text: "", rowLayout: 1, align: "left" },
    ] as any[];
    const columnStyle: ColumnStyleItem[] = [
      { index: 0, isBold: true },
      { index: 1, isBold: true },
      { index: 2, isBold: true },
      { index: 3, isBold: true },
    ];

    if (includeOnePointOneFactor) {
      headers.push({ text: "", rowLayout: 1, align: "left" } as any);
      columnStyle.push({ index: 4, isBold: true });
    }

    table.className = "margin-bottom";
    table.parentsBold = false;
    table.headers = headers;
    table.totalRow = null;

    const rowValues: any[] = [];
    if (includeMonth) {
      rowValues.push("PTD");
    }

    const offsiteHours = tableData.reduce((acc, row) => acc + row.off_site_man_hours, 0);
    const onsiteHours = tableData.reduce((acc, row) => acc + row.on_site_man_hours, 0);
    const totalHours = tableData.reduce((acc, row) => acc + row.total_man_hours, 0);

    rowValues.push(offsiteHours ? offsiteHours.toFixed(1) : "-", onsiteHours ? onsiteHours.toFixed(1) : "-", totalHours ? totalHours.toFixed(1) : "-");

    if (includeOnePointOneFactor) {
      rowValues.push(totalHours ? (totalHours * 1.1).toFixed(1) : "-");
    }

    let targetMet = false;
    if (includePercentAchieved) {
      const totalSectionData = this.inclusionDevelopmentAgreementData.sections.find((section) => section.sectionName === "allEmployees") as EidaReportSection;
      const totalTableData = totalSectionData.tables["eidaTotal"];
      const grandTotal = totalTableData.reduce((acc, row) => acc + row.total_man_hours, 0);

      let percentAchieved = 0;
      if (includeOnePointOneFactor && totalHours) {
        percentAchieved = (totalHours * 1.1) / grandTotal;
      } else if (totalHours) {
        percentAchieved = totalHours / grandTotal;
      }

      if (target && percentAchieved >= target) {
        targetMet = true;
      }

      rowValues.push(percentAchieved ? (percentAchieved * 100).toFixed(1) + "%" : "-");
    }

    if (targetMet) {
      if (includeOnePointOneFactor) {
        columnStyle[4].textColour = "green";
      } else {
        columnStyle[3].textColour = "green";
      }
    }

    table.rows.push({
      data: rowValues,
      children: [],
      columnStyle,
    });

    return table;
  }
}
