
















import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import TargetsTableComponent from "./TargetsTableComponent.vue";
import ReportDataTableComponent from "./ReportDataTableComponent.vue";
import DiversityBreakdownComponent from "./DiversityBreakdownComponent.vue";
import {
  TotalsAggregate,
  CategoryAggregateTotalsValue,
  ReportDataTable,
  TargetDataTableItem,
  ResourceSearchResult,
  Aggregate,
  FacetDetailMeta,
  TotalsMeta,
  ExpensesTotalsMeta,
  ExpenseEmploymentTotalsMeta,
  Vendor,
  CommonMeta,
  TargetDataTable,
  ImpactTargetsMeta,
  ReportDataTableRow,
  ReportDataTableHeader,
} from "../store/models";
import { getTNBTotal } from "../lib/aggregate";
import { EmptyReportDataTable } from "../store/models-empty";
import * as _ from "lodash";
import { getSortedTNBResults } from "@/lib/charts";
import { formatCurrencyToString } from "@/lib/shared";
import ReportHelper, { ReportType } from "./ReportHelper";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    DiversityBreakdownComponent,
    TargetsTableComponent,
  },
})
export default class CCABCommunityRelationsComponent extends BaseComponent {
  @Prop() public aggregate: any;
  @Prop() public yearlyAggregates: any;
  @Prop() public beneficiaryAggregate: any;
  @Prop() public icprojectsAggregate: any;
  @Prop() public impactTargets: any;
  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;
  @Prop() public hideTargets: any;
  @Prop() public enabledFeatures: any;

  private contributionsTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private expenseTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);

  private indigenousProjectBenefitTarget: number = 0;
  private indigenousCommunityContributionTarget: number = 0;
  private indigenousEmploymentTarget: number = 0;
  private isLoaded: boolean = false;

  protected mounted() {
    if (this.aggregate) {
      this.getTargets();
      this.refreshContributionsTable();
      this.refreshExpenseTable();
      this.isLoaded = true;
    }
  }

  private refreshContributionsTable() {
    const isCommunityReportType: boolean = false;
    const isProjectReportType: boolean = false;

    // Total Contributions by Community or Project data table.
    this.contributionsTable.parentsBold = false;
    this.contributionsTable.autoCalculateTotalRow = true;
    this.contributionsTable.title = "Contributions by Community";
    this.contributionsTable.headers = this.reportHelper.contributionsByCommunityHeaders;
    this.contributionsTable.className = "margin-bottom";

    // if (!this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
    //   this.contributionsTable.headers = this.contributionsTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'indigenous-employment');
    // }
    // this.contributionsTable.headers = this.contributionsTable.headers.filter((i: ReportDataTableHeader) => i.id !== "indigenous-employment");
    // Use the icproject aggregate for Community reports.
    const searchResults = isCommunityReportType ? (this.icprojectsAggregate as ResourceSearchResult) : (this.beneficiaryAggregate as ResourceSearchResult);
    const sortedResults = getSortedTNBResults(searchResults, true, true, true);
    for (const result of sortedResults) {
      if (result.total > 0) {
        // Get the result from the search results.
        const searchResult = ((searchResults as ResourceSearchResult).searchResults.results as Aggregate[]).find((x: Aggregate) => (x.facetDetail as FacetDetailMeta).identifier === result.identifier);
        if (searchResult) {
          const expensesTotalsMeta = (searchResult.totals as TotalsMeta).expenses ? ((searchResult.totals as TotalsMeta).expenses as ExpensesTotalsMeta) : null;
          const expenseTotalsMeta = expensesTotalsMeta ? expensesTotalsMeta.expenseTotals : null;
          const employmentMeta = expensesTotalsMeta ? (expensesTotalsMeta.employment as ExpenseEmploymentTotalsMeta) : null;
          if (expenseTotalsMeta) {
            const contributionsRowData: string[] = [];
            contributionsRowData.push(searchResult.facetDetail.displayName);
            contributionsRowData.push(formatCurrencyToString(expenseTotalsMeta.totalIndigenousProjectContributions.toString()));
            if (!isProjectReportType && !isCommunityReportType) {
              contributionsRowData.push(formatCurrencyToString(expenseTotalsMeta.totalIndigenousCommunityContributions.toString()));
            }
            // if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
            contributionsRowData.push(formatCurrencyToString((employmentMeta ? employmentMeta.totalIndigenousEmploymentValue : 0).toString()));
            // }
            contributionsRowData.push(formatCurrencyToString(getTNBTotal(searchResult.totals as TotalsMeta, true).toString()));
            const contributionsRowDataValues: any[] = [];
            contributionsRowDataValues.push(null);
            contributionsRowDataValues.push(expenseTotalsMeta.totalIndigenousProjectContributions);
            if (!isProjectReportType && !isCommunityReportType) {
              contributionsRowDataValues.push(expenseTotalsMeta.totalIndigenousCommunityContributions);
            }
            contributionsRowDataValues.push(employmentMeta ? employmentMeta.totalIndigenousEmploymentValue : 0);
            contributionsRowDataValues.push(getTNBTotal(searchResult.totals as TotalsMeta, true));
            this.contributionsTable.rows.push({
              data: contributionsRowData,
              dataValues: contributionsRowDataValues,
              children: [],
              columnStyle: [{ index: this.contributionsTable.headers.length - 1, isBold: true, isItalic: false }],
            });
          }
        }
      }
    }
  }

  private refreshExpenseTable() {
    const yearlyTotalsAggregate = this.yearlyAggregates as { [key: number]: TotalsAggregate };
    this.expenseTable.parentsBold = false;
    this.expenseTable.title = "Total Contribution by Expense Type by Year";
    this.expenseTable.headers = this.reportHelper.yearlyExpenseContributionsHeaders;
    this.expenseTable.parentHeaders = this.reportHelper.yearlyExpenseContributionsParentHeaders;

    // if (!this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
    //   this.expenseTable.headers = this.expenseTable.headers.filter((i: ReportDataTableHeader) => i.id !== 'indigenous-employment');
    // }
    this.expenseTable.headers = this.expenseTable.headers.filter((i: ReportDataTableHeader) => i.id !== "indigenous-employment");
    this.expenseTable.parentHeaders = this.expenseTable.parentHeaders.filter((i: ReportDataTableHeader) => i.id !== "indigenous-employment");

    let projectTotal = 0;
    let communityTotal = 0;
    // let indigenousTotal = 0;
    const expenseYears = Object.keys(yearlyTotalsAggregate)
      .map(Number)
      .sort((n1, n2) => n2 - n1);
    for (const year of expenseYears) {
      const totalsAggregate = yearlyTotalsAggregate[year];
      const summaryRowData: string[] = [];
      summaryRowData.push(year === new Date().getFullYear() ? "YTD" : year.toString());

      // Project benefits
      summaryRowData.push(totalsAggregate.tnb.projectBenefits.indigenous.valueFormatted);
      summaryRowData.push(`${this.indigenousProjectBenefitTarget}%`);
      summaryRowData.push(totalsAggregate.tnb.projectBenefits.indigenous.percentageFormatted);
      projectTotal += totalsAggregate.tnb.projectBenefits.indigenous.value;

      // Community benefits
      summaryRowData.push(totalsAggregate.tnb.communityContributions.indigenous.valueFormatted);
      summaryRowData.push(`${this.indigenousCommunityContributionTarget}%`);
      summaryRowData.push(totalsAggregate.tnb.communityContributions.indigenous.percentageFormatted);
      communityTotal += totalsAggregate.tnb.communityContributions.indigenous.value;

      // Indigenous employment
      // if (this.isFeatureEnabled(this.features.indigenousEmploymentReports)) {
      //   summaryRowData.push(totalsAggregate.tnb.employment.indigenous.valueFormatted);
      //   summaryRowData.push(`${this.indigenousEmploymentTarget}%`);
      //   summaryRowData.push(totalsAggregate.tnb.employment.indigenous.percentageFormatted);
      //   indigenousTotal += totalsAggregate.tnb.employment.indigenous.value;
      // }
      this.expenseTable.rows.push({
        data: summaryRowData,
        dataValues: [],
        children: [],
        columnStyle: [],
      });
    }
    this.expenseTable.totalRow = {
      data: [
        formatCurrencyToString(projectTotal.toString()),
        "",
        "",
        formatCurrencyToString(communityTotal.toString()),
        "",
        "",
        // formatCurrencyToString(indigenousTotal.toString()),
        // "",
        // "",
      ],
    } as ReportDataTableRow;
  }

  private getTargets() {
    const impactTargets = this.impactTargets as ImpactTargetsMeta;
    this.indigenousCommunityContributionTarget = this.zeroIfUndefined(impactTargets.indigenousCommunityContribution);
    this.indigenousEmploymentTarget = this.zeroIfUndefined(impactTargets.indigenousEmployment);
    this.indigenousProjectBenefitTarget = this.zeroIfUndefined(impactTargets.indigenousProjectBenefit);
  }
}
