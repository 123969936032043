















import { Component, Prop } from "vue-property-decorator";
import BaseComponent from "./BaseComponent";
import SpinnerComponent from "./SpinnerComponent.vue";
import ReportDataTableComponent from "../components/ReportDataTableComponent.vue";
import TargetComponent from "../components/TargetComponent.vue";
import {
  TotalsAggregate,
  CategoryAggregateTotalsValue,
  ReportDataTable,
  TotalsAggregateValue,
  TargetDataTableItem,
  ResourceSearchResult,
  Expense,
  ExpenseAggregate,
  Vendor,
  ShortRecord,
  CommonMeta,
  AffiliatedVendorsMeta,
} from "../store/models";
import { formatTotalForDisplay } from "../lib/aggregate";
import { EmptyReportDataTable, EmptyReportDataTableRow, EmptyTargetDataTableItem } from "../store/models-empty";
import * as _ from "lodash";
import ReportHelper from "./ReportHelper";
import { formatCurrencyToString } from "@/lib/shared";

@Component({
  components: {
    SpinnerComponent,
    ReportDataTableComponent,
    TargetComponent,
  },
})
export default class SocialProcurementReportComponent extends BaseComponent {
  @Prop() public overheadExpenseData!: ExpenseAggregate[];
  @Prop() public overheadServicesExpenseData!: ExpenseAggregate[];
  @Prop() public overheadGoodsExpenseData!: ExpenseAggregate[];
  @Prop() public overheadEquipmentExpenseData!: ExpenseAggregate[];
  @Prop() public overheadContributionsExpenseData!: ExpenseAggregate[];
  @Prop() public payeeDetails!: Record<string, Vendor>;

  @Prop() public reportHelper: any;
  @Prop() public reportMode: any;

  private procurementTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private contributionsTable: ReportDataTable = _.cloneDeep(EmptyReportDataTable);
  private isLoaded: boolean = false;

  protected mounted() {
    this.createProcurementTable();
    this.createContributionTable();
    this.isLoaded = true;
  }

  private createProcurementTable() {
    this.procurementTable.title = "Procurement";
    this.procurementTable.className = "margin-bottom";
    this.procurementTable.headers = this.reportHelper.locationOverheadsPayeeHeaders;
    this.procurementTable.parentsBold = false;
    this.procurementTable.totalRow = null;

    let total = 0;

    total += this.addProcurementPayeeRows(this.overheadServicesExpenseData, "Services");
    total += this.addProcurementPayeeRows(this.overheadGoodsExpenseData, "Goods");
    total += this.addProcurementPayeeRows(this.overheadEquipmentExpenseData, "Equipment");

    this.procurementTable.totalRow = {
      data: ["", formatCurrencyToString(total.toFixed(2), false)],
      children: [],
    };
  }

  private addProcurementPayeeRows(expenses: ExpenseAggregate[], type: string) {
    let total = 0;

    for (const agg of expenses) {
      total += agg.payload.expenses.expenseTotals.totalSpend;
      const payeeDetails = this.getPayeeDetails(agg.identifier);
      this.procurementTable.rows.push({
        data: [payeeDetails.displayName, type, formatCurrencyToString(agg.payload.expenses.expenseTotals.totalSpend.toFixed(2), false), payeeDetails.indigenousOwnership, payeeDetails.diversityClass],
        children: [],
      });
    }

    return total;
  }

  private createContributionTable() {
    this.contributionsTable.title = "Contributions";
    this.contributionsTable.className = "margin-bottom";
    this.contributionsTable.headers = this.reportHelper.locationOverheadContributionsHeaders;
    this.contributionsTable.parentsBold = false;

    let total = 0;

    for (const agg of this.overheadContributionsExpenseData) {
      total += agg.payload.expenses.expenseTotals.totalSpend;
      const payeeDetails = this.getPayeeDetails(agg.identifier);
      this.contributionsTable.rows.push({
        data: [payeeDetails.displayName, formatCurrencyToString(agg.payload.expenses.expenseTotals.totalSpend.toFixed(2), false), payeeDetails.indigenousOwnership, payeeDetails.diversityClass],
        children: [],
      });
    }

    this.contributionsTable.totalRow = {
      data: [formatCurrencyToString(total.toFixed(2), false)],
      children: [],
    };
  }

  private getPayeeDetails(payee: string): { displayName: string; diversityClass: string; indigenousOwnership: string } {
    if (!this.payeeDetails[payee]) {
      return {
        displayName: payee,
        diversityClass: "",
        indigenousOwnership: "",
      };
    }

    const indigenousOwnership = this.payeeDetails[payee].vendor.proxyPayeeFor ? ((this.payeeDetails[payee].vendor.proxyPayeeFor as ShortRecord).displayName as string) : "";
    const diversityClass = this.reportHelper.getSupplyChainDiversityDescription(this.payeeDetails[payee].diversityDeclarations);
    const displayName = this.payeeDetails[payee].common ? ((this.payeeDetails[payee].common as CommonMeta).displayName as string) : "";

    return {
      displayName,
      diversityClass,
      indigenousOwnership,
    };
  }
}
